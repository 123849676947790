import { sharedServices } from "../services/sharedServices";
import { ref as refDb, set, onValue, get, child } from "firebase/database";
import database from "../../firebase/config";
const root = { root: true };

export const validateHttpStatus = async (
  { commit, dispatch },
  { response, isShowError = true, isShowSuccess = false }
) => {
  const status = response.status;
  const resJSON = await response.json();

  const resStatus = {};
  resStatus.success = false;
  resStatus.json = resJSON;

  let arrayErrors = [];
  /* CODES  */
  const codesSuccess = [200, 201];
  const codesError = [401, 500, 404, 400];

  if (status === 422) {
    for (var [_, value] of Object.entries(resJSON.errors)) {
      arrayErrors.push(value);
    }
  } else if (status === 500 && resJSON.file) {
    arrayErrors.push("Please contact the administrator");
  } else if (codesError.includes(status)) {
    if (resJSON.message) arrayErrors.push(resJSON.message);
    if (resJSON.error) arrayErrors.push(resJSON.error);
  } else if (codesSuccess.includes(status) || resJSON.status === "success") {
    resStatus.success = true;
  }
  if (arrayErrors.length > 0) {
    arrayErrors = arrayErrors.flat();
    resStatus.json.errors = arrayErrors.flat();
  }
  if (arrayErrors.length > 0 && isShowError) {
    commit("uiNotificationStatus", {
      status: "error",
      message: arrayErrors[0],
      isOpen: true,
    });
  }

  if (resStatus.success && isShowSuccess) {
    commit("uiNotificationStatus", {
      status: "success",
      message: resJSON.message,
      isOpen: true,
    });
  }
  /* RESET MESSAGE */
  dispatch("resetNotificationStatus");
  return resStatus;
};

export const resetNotificationStatus = ({ commit }) => {
  setTimeout(() => {
    commit("uiNotificationStatus", {
      status: "",
      message: "",
      isOpen: false,
    });
  }, 30000);
};

export const validateUserToken = async ({ commit, dispatch }, { response }) => {
  const status = response.status;
  const resJSON = await response.clone().json();

  if (status === 401 && resJSON.message === "Unauthenticated.") {
    commit("uiNotificationStatus", {
      status: "info",
      message: "Your session has expired, please log in again.",
      isOpen: true,
    });
    const info_user = JSON.parse(localStorage.getItem("bl-user"));
    if (info_user) {
      if (
        info_user.token_firebase !== undefined &&
        info_user.token_firebase !== "" &&
        info_user.token_firebase !== null
      ) {
        if (info_user.role === "student") {
          set(
            refDb(database, "Spanish/" + info_user.token_firebase + "/status"),
            "offline"
          );
        } else if (info_user.role === "teacher") {
          set(
            refDb(database, "Teachers/" + info_user.token_firebase + "/status"),
            "offline"
          );
        } else if (info_user.role === "admin") {
          set(
            refDb(database, "Admins/" + info_user.token_firebase + "/status"),
            "offline"
          );
        }
      }
    }

    setTimeout(() => {
      commit("uiNotificationStatus", {
        status: "",
        message: "",
        isOpen: false,
      });
      localStorage.removeItem("bl-user");
      commit(
        "shared/userAuthentication",
        { role: null, token: null, isLogged: false },
        root
      );
      window.location.reload();
    }, 3000);
    return { success: false };
  }
  if (status === 500 && resJSON.file) {
    commit("uiNotificationStatus", {
      status: "warning",
      message:
        "An error has been encountered in the system, please contact an administrator.",
      isOpen: true,
    });
    setTimeout(() => {
      commit("uiNotificationStatus", {
        status: "",
        message: "",
        isOpen: false,
      });
    }, 3000);
    return { success: false };
  }
  return { success: true, resJSON };
};

export const getListings = async ({ commit }) => {
  try {
    const countriesCodes = sharedServices.getPhoneCodes();
    const levelsPromise = sharedServices.getLevels();
    const timeZonesPromise = sharedServices.getTimeZones();
    const spanishKnowledgePromise = sharedServices.getSpanishKnowledge();
    const learningPathsPromise = sharedServices.getLearningPath();
    // const emailTemplatesPromise = sharedServices.emailTemplates();
    let [
      countriesResp,
      levelsResp,
      timeZonesResp,
      spanishKnowResp,
      learningPathResp,
      // emailTemplatesResp,
    ] = await Promise.all([
      countriesCodes,
      levelsPromise,
      timeZonesPromise,
      spanishKnowledgePromise,
      learningPathsPromise,
      // emailTemplatesPromise,
    ]);
    // emailTemplatesResp = await emailTemplatesResp.json();
    countriesResp = await countriesResp.json();
    levelsResp = await levelsResp.json();
    /* TIME ZONES */
    timeZonesResp = await timeZonesResp.json();
    let timeZones = [];
    if (Object.values(timeZonesResp).length > 0) {
      for (var [key, value] of Object.entries(timeZonesResp)) {
        if (key) timeZones.push(value);
      }
    }
    timeZonesResp = timeZones.flat();

    spanishKnowResp = await spanishKnowResp.json();
    learningPathResp = await learningPathResp.json();

    commit(
      "shared/setListing",
      {
        countrycodes: { data: countriesResp, isLoading: false },
        levels: { data: levelsResp, isLoading: false },
        timesZones: { data: timeZonesResp, isLoading: false },
        spanishKnowledge: { data: spanishKnowResp, isLoading: false },
        learningPath: { data: learningPathResp, isLoading: false },
        // emailTemplates: { data: emailTemplatesResp, isLoading: false },
      },

      root
    );
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const createTemplateEmail = async (
  { commit, dispatch },
  { payload }
) => {
  try {
    const createTemplateEmailPromise = await sharedServices.createTemplateEmail(
      payload
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: createTemplateEmailPromise, isShowSuccess: true },
      root
    );

    return { success, json };
  } catch (error) {
    //console.log(error);
  } finally {
  }
};

export const levelsInfo = async ({ commit, dispatch }) => {
  try {
    const levelsInfoPromise = await sharedServices.levelsInfo();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: levelsInfoPromise, isShowSuccess: false },
      root
    );

    return { success, json };
  } catch (error) {
    //console.log(error);
  } finally {
  }
};

export const getCurrentTime = async ({ commit, dispatch}) => {
  try {
    const getTime = await sharedServices.currentTime();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: getTime, isShowSuccess: false },
      root
    );
    commit("currentTime", json.time)
    return { success, json };
  } catch (error) {
    //console.log(error);
  } finally {
  }
};
