export const studentToggleUpgradeLevelModal = (state, payload) => {
  state.modals.student.updateLevel = {
    ...state.modals.student.updateLevel,
    ...payload,
  };
};

export const studentToggleFeedbackModal = (state, payload) => {
  state.modals.student.feedback = {
    ...state.modals.student.feedback,
    ...payload,
  };
};

export const teacherInfo = (state, payload) => {
  state.teacher.info = {
    ...state.teacher.info,
    ...payload,
  };
};

export const students = (state, payload) => {
  state.students = {
    ...state.students,
    ...payload,
  };
};

export const student = (state, payload) => {
  state.student = {
    ...state.student,
    ...payload,
  };
};


export const dashboardStart = (state,payload) => {
  state.dashboard = {
    ...state.dashboard,
    ...payload
  }
}


export const teacherClassesTaughtThisWeek = (state,payload) => {
  state.teacher.classes.takenThisWeek ={
    ...state.teacher.classes.takenThisWeek,
    ...payload
  }
}

export const teacherClassesCalendar = (state,payload) => {
  state.teacher.classes.isCalendar ={
    ...state.teacher.classes.isCalendar,
    ...payload
  }
}

export const teacherClassesForDay = (state,payload) => {
  state.teacher.classes.forDay ={
    ...state.teacher.classes.forDay,
    ...payload
  }
}

export const classModal = (state ,  payload) => {
  state.classModal = {
    ...state.classModal,
    ...payload
  }
}

export const allClasses = (state,payload) => {
  state.teacher.classes.allClasses = {
    ...state.teacher.classes.allClasses,
    ...payload
  }
}

export const toggleModalResetPassword = (state,payload) => {
  state.modals.student.resetPassword = {
    ...state.modals.student.resetPassword,
    ...payload
  }
}

export const questions = (state,payload) => {
  state.questions = {
    ...state.questions,
    ...payload
  }
}

export const coordinators = (state,payload) => {
  state.coordinators = {
    ...state.coordinators,
    ...payload
  }
}

export const finishClasses = (state,payload) => {
  state.teacher.classes.finishedClasses = {
    ...state.teacher.classes.finishedClasses,
    ...payload
  }
}

export const teacherNote = (state,payload) => {
  state.modals.student.teacherNote = {
    ...state.modals.student.teacherNote,
    ...payload
  }
}