import getUserAuthenticated from "@/shared/helpers/getUserAuthenticated";

const isAuthenticatedGuard = async (to, from, next) => {
  const routeName = to.name;
  const previousRouteName = from.name ?? "login";

  /* validate session and role */
  let { isLogged, role, completed_info } = getUserAuthenticated();
  if (role === "coordinator") role = "admin";
  if (isLogged) {
    /* validate role */
    if (!routeName.startsWith(role)) {
      if (from.name) next({ name: from.name });
      else if (!from.name) next({ name: role + "-dashboard" });
    } else {
      if (completed_info) {
        next();
      }
      else if (
        !completed_info &&
        to.name !== "student-dashboard" &&
        role === "student"
      ) {
        next({ name: "student-dashboard" });
      } else {
        next();
      }
    }
  } else {
    next({ name: previousRouteName });
  }
};

export default isAuthenticatedGuard;
