import { studentsServices } from "../services/studentsServices";
// import convertPlainText from "@/shared/utils/changeTexts/convertPlainTextUtil";
import moment from "moment";
import { getFollowedClasses } from "../../../shared/helpers/getFollowedClasses";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";

const root = { root: true };

/************  STUDENT INFO ********/
export const getStudentInfo = async ({ commit }) => {
  commit(
    "students/studentInformation",
    {
      information: {
        isLoading: true,
      },
    },
    root
  );
  try {
    const profilePromise = await studentsServices.getStudentInfo();

    if (profilePromise) {
      const {
        student_info: information,
        info_card: card,
        statistics_lessons,
      } = await profilePromise.json();

      const student = {
        information: information ?? {},
        card: card ?? {},
        statistics_lessons: statistics_lessons ?? {},
      };
      student.information.isLoading = false;
      commit("students/studentInformation", student, root);
      commit("admins/adminInfo", { isLoading: false }, root);
      /* VALIDATE CREDIT CARD */
      const countKeys = Object.keys(student.card).length - 1;
      const hasCreditCard =
        Object.values(student.card).filter((val) => val).length >= countKeys;
      commit(
        "students/billingTooglePayment",
        { hasCreditCard, mood: hasCreditCard ? "" : "new", isLoading: false },
        root
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTeachersFavorites = async ({ commit }) => {
  try {
    const teachersFavoritesPromise =
      await studentsServices.getTeachersFavorites();
    if (teachersFavoritesPromise) {
      const teachersJSON = await teachersFavoritesPromise.json();
      commit(
        "students/setTeachersFavorites",
        { data: teachersJSON, isLoading: false },
        root
      );
    }
  } catch (ex) {
    console.error(ex);
  }
};

/* ********* TEACHERS INFO ****************** */
export const getTeachersAll = async ({ commit, getters }) => {
  try {
    const teacherPromise = await studentsServices.getTeachersAll();
    if (teacherPromise) {
      const teacherResponse = await teacherPromise.json();
      if (Array.isArray(teacherResponse) && teacherResponse.length > 0) {
        let { all } = getters.getTeachers;
        if (all.filter) {
          let { search } = all;
          const searchFormat = search.split(' ').join('').toLowerCase()

          const data = teacherResponse.filter((teacher)=> {
            const teacherName = (teacher.name+teacher?.last_name)
            .split(' ').join('').toLowerCase()
            console.log(teacherName)
            return (teacherName).includes(searchFormat)
          })


          console.log(searchFormat)

          console.log(data)
          
          // let data = teacherResponse.filter(
          //   (p) => p.name.toLowerCase().includes(search) || p?.last_name.toLowerCase().includes(search)
          // );
          commit(
            "students/setTeachersAll",
            { data: data, isLoading: false },
            root
          );
        } else
          commit(
            "students/setTeachersAll",
            { data: teacherResponse, isLoading: false },
            root
          );
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const getTeachersOnnline = (teachers,commit) => {
  teachers.forEach((teacher,index) => {
    const dbRefLastStatus = refDb(
      database,
      "Teachers/" + teacher.token_firebase + "/last_status"
    );
    const dbRefGet = refDb(database);
    onValue(dbRefLastStatus, (snapshot) => {
      if (snapshot.val() !== "online" && snapshot.val() !== "offline") {
        teacher.status = snapshot.val();
        teacher.see = true;
        commit(
          "students/setTeachersAllAvailable",
          { data: teachers, isLoading: false },
          root
        );
      } else {
        get(child(dbRefGet, "Teachers/" + teacher.token_firebase + "/status"))
          .then((snapshot2) => {
            teacher.status = snapshot2.val();
            if(teacher.status === "offline")
              teacher.see = false;
            commit(
              "students/setTeachersAllAvailable",
              { data: teachers, isLoading: false },
              root
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });

    const dbRefStatus = refDb(database, "Teachers/" + teacher.token_firebase + "/status");
    onValue(dbRefStatus, (snapshot) => {
      if (snapshot.val() === "online") {
        get(
          child(
            dbRefGet,
            "Teachers/" + teacher.token_firebase + "/last_status"
          )
        )
          .then((snapshot2) => {
            if (snapshot2.val() !== "online" && snapshot2.val() !== "offline") {
              teacher.status = snapshot2.val();
              teacher.see = true;
              commit(
                "students/setTeachersAllAvailable",
                { data: teachers, isLoading: false },
                root
              );
            } else {
              teacher.status = "online";
              teacher.see = true;
              commit(
                "students/setTeachersAllAvailable",
                { data: teachers, isLoading: false },
                root
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        teacher.status = snapshot.val();
        teacher.see = false
        commit(
          "students/setTeachersAllAvailable",
          { data: teachers, isLoading: false },
          root
        );
      }
    });

    
  })
 
}

/* ********* TEACHERS INFO ****************** */
export const getTeachersAllAvailable = async ({ commit, getters } , { payload  }) => {
  try {
    commit("students/availableTeachers", { isLoading: true }, root);
    const availableTimePromise = await studentsServices.availableTime(payload);
    if (availableTimePromise) {
      const availableTimeJSON = await availableTimePromise.json();
      let response = Object.entries(availableTimeJSON)[0][1];
      getTeachersOnnline(response,commit)

      commit(
        "students/setTeachersAllAvailable",
        { isLoading: false },
        root
      );
     
    }
  } catch (ex) {
    console.log(ex);
  }
};

/* ******************* HELP *************** */
export const getHelpQuestions = async ({ commit }) => {
  const questionsPromise = await studentsServices.getHelpQuestions();
  const categoriesPromise = await studentsServices.getHelpCategories();

  const [questionsRes, categoriesResp] = await Promise.all([
    questionsPromise,
    categoriesPromise,
  ]);

  let categoriesJSON = await categoriesResp.json();
  let questionsJSON = await questionsRes.json();

  if (categoriesJSON.length > 0 && questionsJSON.length > 0) {
    /** FORMATING JSON **/
    questionsJSON = questionsJSON.map((q) => {
      if (q.status === "publish") {
        return {
          id: q.id,
          question: q.title.rendered,
          answer: q.content.rendered,
          categoryID: q.categories[0],
        };
      }
    });

    const helperQuestions = categoriesJSON.map((c) => {
      const { id, description, name } = c;
      const questions = questionsJSON.filter(
        ({ categoryID }) => categoryID === c.id
      );

      return {
        categoryID: id,
        icon: description,
        category: name,
        questions,
      };
    });

    commit("students/helpQuestions", helperQuestions, root);
  }
};

/* ********* REWARDS ***************************** */
export const getRewardsTypesDiscounts = async ({ commit }) => {
  try {
    const typesDiscountsPromise =
      await studentsServices.getRewardTypesDiscounts();
    const discountCodePromise =
      await studentsServices.getRewardMyDiscountCode();

    const [typesDiscountsRes, discountCodeResp] = await Promise.all([
      typesDiscountsPromise,
      discountCodePromise,
    ]);

    let typesDiscountsJSON = await typesDiscountsRes.json();
    let discountCodeJSON = await discountCodeResp.json();

    if (discountCodeJSON.discount_code) {
      const code = discountCodeJSON.discount_code;
      commit("students/rewardDiscountCode", code, root);
    }

    if (typesDiscountsJSON.length > 0) {
      let typesDiscounts = typesDiscountsJSON.filter(
        (r) => r.status === "publish"
      );
      typesDiscounts = typesDiscounts.map((r) => r.acf).reverse();
      commit(
        "students/rewardTypesDiscounts",
        { typesDiscounts, isLoading: false },
        root
      );
    }
  } catch (error) {
    //console.log(error);
  }
};

export const libraryLevels = async ({ commit }) => {
  try {
    const libraryPromise = await studentsServices.getLevels();
    if (libraryPromise) {
      const libraryJSON = await libraryPromise.json();
      const levels = libraryJSON.general_content;
      const currentLevel = libraryJSON.general_content[0];
      commit(
        "shared/updateLibrary",
        {
          levels,
          currentLevel,
          currentLevelNext: levels[1],
        },
        root
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const dashboardStart = async ({ commit, rootGetters }) => {
  try {
    const dashboardPromise = await studentsServices.getStart();
    if (dashboardPromise) {
      const dashboardJSON = await dashboardPromise.json();
      let { student_my_lessons_day, student_my_lessons_month } = dashboardJSON;
      let total = 0;
      let totalMonth = 0;
      let totalWeek = 0;
      let currentMonth = new Date(rootGetters['shared/currentTimeTimezone']).getMonth();
      student_my_lessons_month?.forEach((month) => {
        total += month.count;
        if (month.month_of_year === currentMonth) {
          totalMonth = month.count;
        }
      });
      student_my_lessons_day?.forEach((day) => {
        totalWeek += day.count;
      });

      if (dashboardJSON.student_lessons_of_day?.length > 0) {
        var currentDate = moment(new Date(rootGetters['shared/currentTimeTimezone']), "YYYY-MM-DD HH:mm:ss");
        var date = moment(
          dashboardJSON.student_lessons_of_day[0].lesson_start,
          "YYYY-MM-DD HH:mm:ss"
        );

        if (date > currentDate) {
          commit(
            "shared/uiToggleNotificationClass",
            {
              data: dashboardJSON.student_lessons_of_day[0],
              isOpen: true,
            },
            root
          );
        } else {
            commit(
              "shared/uiToggleNotificationClass",
              {
                data: {},
                isOpen: false,
              },
              root
            );
        }
      }else{
        commit(
          "shared/uiToggleNotificationClass",
          {
            data: {},
            isOpen: false,
          },
          root
        );
      }
      commit(
        "students/dashboardStart",
        {
          data: { ...dashboardJSON },
          isLoading: false,
          totalClasses: total,
          totalClassesMonth: totalMonth,
          totalClassesWeek: totalWeek,
        },
        root
      );
    }
  } catch (ex) {
    console.error(ex);
  }
};

export const classesTakenThisWeek = async ({ commit }) => {
  try {
    const classesTakenThisWeekPromise = await studentsServices.getClassesWeek();
    if (classesTakenThisWeekPromise) {
      const classesWeekJSON = await classesTakenThisWeekPromise.json();
      commit(
        "students/classesTakenThisWeek",
        {
          data: classesWeekJSON,
          isLoading: false,
          total: classesWeekJSON.length,
        },
        root
      );
    }
  } catch (ex) {
    console.error(ex);
  }
};

export const classesTakenThisDay = async ({ commit }) => {
  try {
    const classesTakenThisDayPromise = await studentsServices.getClassesDay();
    if (classesTakenThisDayPromise) {
      const classesDayJSON = await classesTakenThisDayPromise.json();
      commit(
        "students/classesTakenThisDay",
        {
          data: classesDayJSON,
          isLoading: false,
        },
        root
      );
    }
  } catch (ex) {
    console.error(ex);
  }
};


export const classesThisWeekPending = async ({ commit }) => {
  try {
    const classesThisWeekPendingPromise = await studentsServices.pendingClasses();
    if (classesThisWeekPendingPromise) {
      const classesThisWeekPendingJSON = await classesThisWeekPendingPromise.json();
      commit(
        "students/classesThisWeekPending",
        {
          data: classesThisWeekPendingJSON,
          isLoading: false,
          total: classesThisWeekPendingJSON.length,
        },
        root
      );
    }
  } catch (ex) {
    console.error(ex);
  }
};

export const lastClass = async ({ commit }) => {
  try {
    const lastClassPromise = await studentsServices.getLastClass();
    if (lastClassPromise) {
      const lastClassJSON = await lastClassPromise.json();
      commit(
        "students/lastClass",
        {
          data: lastClassJSON,
          isLoading: false,
        },
        root
      );
    }
  } catch (ex) {
    console.error(ex);
  }
};

export const feedbacks = async ({ commit }) => {
  try {
    const feedbacksPromise = await studentsServices.getFeedbacks();
    if (feedbacksPromise) {
      const feedbacksJSON = await feedbacksPromise.json();
      const newFeedbacksJSON = getFollowedClasses(feedbacksJSON);
      commit(
        "students/feedbacks",
        {
          isLoading: false,
          data: newFeedbacksJSON,
        },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const profilePersonalDetailsStatus = async (
  { commit, dispatch },
  { payload }
) => {
  try {
    let res = false;
    const profilePromise = await studentsServices.editProfileStudent(payload);
    if (profilePromise) {
      const profileJSON = await profilePromise.json();
      const notification = { isOpen: true };
      if (profileJSON.errors) {
        let errors = [];
        notification.message = "The fields ( ";
        Object.entries(profileJSON.errors).forEach(([key, value]) => {
          errors.push(key);
          if(key === "learning_path_id")
            notification.message += `Learning Path, `;
          else if(key === "spanish_knowledge_id")
            notification.message += `You Spanish knowledge, `;
          else if(key === "phone_code_id")
            notification.message += `Phone code, `;
          else
            notification.message += `${key}, `;
        });
        notification.message += ") are required.";
        notification.status = "error";
      } else {
        const profile = await studentsServices.getStudentInfo()
        const profileJson = await profile.json()
        const timeZone = profileJson.student_info.timezone
        res = true;
        notification.message = "Your profile was successfully updated.";
        let bl_user = JSON.parse(localStorage.getItem("bl-user"));
        bl_user.completed_info = true;
        bl_user.timezone = timeZone;
        localStorage.setItem("bl-user", JSON.stringify(bl_user));
        notification.status = "success";
        commit(
          "students/profilePersonalDetailsStatus",
          { mood: "update" },
          root
        );
        commit("shared/userAuthentication", { ...bl_user }, root);
      }
      commit("shared/uiNotificationStatus", notification, root);
    }
    return { res };
  } catch (ex) {
    //console.log(ex);
  }
};

export const editPhotoStudent = async ({ dispatch }, { payload }) => {
  try {
    const profilePhotoPromise = await studentsServices.editPhotoStudent(
      payload
    );

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: profilePhotoPromise, isShowSuccess: true },
      root
    );

    return { success, json };
  } catch (ex) {
    //console.log(ex);
  }
};

export const markedCompleted = async (
  { commit },
  { level_info, completed }
) => {
  try {
    const markedCompletedPromise = await studentsServices.markedCompleted(
      level_info.content.subtitle_id
    );
    if (markedCompletedPromise) {
      const notification = { isOpen: true };
      const markedCompletedJSON = await markedCompletedPromise.json();
      if (markedCompletedJSON.errors) {
        notification.message = "Can't marked your subtitle how completed.";
        notification.status = "error";
        commit("shared/uiNotificationStatus", notification, root);
      } else {
        notification.message = `${
          completed
            ? "Topic marked as completed"
            : "Topic unmarked as completed"
        } successfully.`;
        notification.status = "success";
        commit("shared/uiNotificationStatus", notification, root);
      }
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const questions = async ({ commit }) => {
  try {
    const questionsPromise = await studentsServices.questionsTeachers();
    if (questionsPromise) {
      const questionsPromiseJSON = await questionsPromise.json();
      commit(
        "students/questions",
        { data: questionsPromiseJSON, isLoading: false },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const sendFeedback = async ({ commit, dispatch }, { payload }) => {
  try {
    const sendFeedbackPromise = await studentsServices.sendFeedback(payload);
    if (sendFeedbackPromise) {
      const sendFeedbackPromiseJSON = await sendFeedbackPromise.json();
      const notification = { isOpen: true };
      let res = true;
      if (sendFeedbackPromiseJSON.status === "error") {
        notification.message = "Please try again and verify that you have filled all the fields.";
        notification.status = "error";
        res = false;
      } else {
        notification.message = `Your feedback was submitted successfully.`;
        notification.status = "success";
        commit(
          "students/studentToggleLastTeacherModal",
          { isOpen: false },
          root
        );
      }
      commit("shared/uiNotificationStatus", notification, root);

      return res;
    }
  } catch (ex) {
    const notification = { isOpen: true, message: 'Please try again and verify that you have filled all the fields.', status: 'error' };
    commit("shared/uiNotificationStatus", notification, root);
    return false
  }
};

export const linkGoogleEmail = async ({ commit }, { email }) => {
  try {
    const linkGoogleEmailPromise = await studentsServices.linkGoogleEmail(
      email
    );
    if (linkGoogleEmailPromise) {
      const linkGoogleEmailJSON = await linkGoogleEmailPromise.json();
      const notification = { isOpen: true };
      if (linkGoogleEmailJSON.status === "error") {
        notification.message = "We can't linked your google.";
        notification.status = "error";
      } else {
        notification.message = `Your google account is linked successfully.`;
        notification.status = "success";
        commit(
          "students/studentToggleLastTeacherModal",
          { isOpen: false },
          root
        );
      }
      commit("shared/uiNotificationStatus", notification, root);
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const unLinkGoogleEmail = async ({ commit, dispatch }) => {
  try {
    const unLinkGoogleEmailPromise = await studentsServices.unLinkGoogleEmail();
    if (unLinkGoogleEmailPromise) {
      const unLinkGoogleEmailJSON = await unLinkGoogleEmailPromise.json();
      const notification = { isOpen: true };
      if (unLinkGoogleEmailJSON.status !== "error") {
        notification.message = `Your google account is unlinked successfully.`;
        notification.status = "success";
        commit(
          "students/studentToggleLastTeacherModal",
          { isOpen: false },
          root
        );
        commit("shared/uiNotificationStatus", notification, root);
      }
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const addRemoveFavorite = async ({ commit }, { id }) => {
  try {
    const addRemoveFavoritePromise = await studentsServices.addRemoveFavorite(
      id
    );
    let isRemoveAdd = false
    if (addRemoveFavoritePromise) {
      const addRemoveFavoriteJSON = await addRemoveFavoritePromise.json();
      const notification = { isOpen: true };
      if (addRemoveFavoriteJSON.status === "error") {
        notification.message =
          "Can’t add more than 5 favorite teachers, sorry :(";
        notification.status = "error";
      } else {
        notification.message = `${addRemoveFavoriteJSON.message}`;
        notification.status = "success";
        if(addRemoveFavoriteJSON.message === "Teacher Favorite Add"){
          isRemoveAdd = true
        }
      }
      commit("students/setTeachersFavorites", { isLoading: true }, root);
      commit("students/teacherToggleInfoModal", { isOpen: false }, root);
      commit("shared/uiNotificationStatus", notification, root);
      return isRemoveAdd
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const cancelClass = async ({ commit, dispatch }, { id }) => {
  try {
    const cancelClassPromise = await studentsServices.cancelClass(id);
    if (cancelClassPromise) {
      const cancelClassJSON = await cancelClassPromise.json();
      // console.log(cancelClassJSON);
      const notification = { isOpen: true };
      if (cancelClassJSON.status === "error") {
        notification.status = "error";
      } else {
        notification.status = "success";
      }
      notification.message = `${cancelClassJSON.message}`;
      commit("shared/uiNotificationStatus", notification, root);
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const availableTeachers = async ({ commit, dispatch }, { payload }) => {
  try {
    commit("students/availableTeachers", { isLoading: true }, root);
    const availableTimePromise = await studentsServices.availableTime(payload);
    if (availableTimePromise) {
      const availableTimeJSON = await availableTimePromise.json();
      console.log(availableTimeJSON)
      if (Object.entries(availableTimeJSON).length > 0) {
        Object.entries(availableTimeJSON).forEach(([key,value]) => {
          if(value.length === 0)
            delete availableTeachers[key]
        })
        commit(
          "students/availableTeachers",
          { data: availableTimeJSON, isLoading: false },
          root
        );
      } 
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const createClassByTime = async ({ commit, dispatch }, { payload }) => {
  let res = false;
  try {
    const createClassByTimePromise = await studentsServices.createClassByTime(
      payload
    );
    if (createClassByTimePromise) {
      const createClassByTimeJSON = await createClassByTimePromise.json();
      const notification = { isOpen: true };
      if (createClassByTimeJSON.status === "error") {
        notification.status = "error";
        notification.message = `${createClassByTimeJSON.message}`;
      } else {
        res = true;
        notification.status = "success";
        notification.message = `Your class(es) were booked successfully.`;
      }
      commit("shared/uiNotificationStatus", notification, root);
      return { res };
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const availablescheduleds = async ({ commit, dispatch }, { id }) => {
  try {
    const availablescheduledsPromise = await studentsServices.availablescheduleds(
      id
    );
    if (availablescheduledsPromise) {
      const availablescheduledJSON = await availablescheduledsPromise.json();
      if (Object.entries(availablescheduledJSON).length > 0) {
        // commit("students/availablescheduleds",{data:availablescheduledJSON,isLoading:false},root);
      } else {
        const notification = { isOpen: true };
        notification.status = "error";
        notification.message = `The are  not scheduleds available for this teacher.`;
        commit("shared/uiNotificationStatus", notification, root);
      }
      commit(
        "students/availablescheduleds",
        { data: availablescheduledJSON, isLoading: false },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const deleteCard = async ({ dispatch, commit } , { payload }) => {
  try {
    const deleteCardPromise = await studentsServices.deleteCard(payload);

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: deleteCardPromise, isShowError: false, isShowSuccess: false },
      root
    );
    if(json?.type === 'card_error'){
      commit("shared/uiNotificationStatus", {
        status: "error",
        message: json.message,
        isOpen: true,
      }, { root: true });
      dispatch("shared/resetNotificationStatus", { root: true });
    }
    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};

export const validateCard = async ({ dispatch, commit } , { payload }) => {
  try {
    const validateCardPromise = await studentsServices.validateCard(payload);

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: validateCardPromise, isShowError: true, isShowSuccess: false },
      root
    );
    // if(json?.type === 'card_error'){
    //   commit("shared/uiNotificationStatus", {
    //     status: "error",
    //     message: json.message,
    //     isOpen: true,
    //   }, { root: true });
    //   dispatch("shared/resetNotificationStatus", { root: true });
    // }
    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};

export const addCard = async ({ commit, dispatch }, { payload }) => {
  try {
    const addCardPromise = await studentsServices.addCard(payload);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: addCardPromise, isShowSuccess: true, isShowError: false },
      root
    );
    if(json?.type === 'card_error'){
      commit("shared/uiNotificationStatus", {
        status: "error",
        message: json.message,
        isOpen: true,
      }, { root: true });
      dispatch("shared/resetNotificationStatus", { root: true });
    }
    return {
      success,
      json,
    };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};
export const billingSuscribe = async ({ commit, dispatch }, { payload }) => {
  try {
    const billingSuscribePromise = await studentsServices.billingSuscribe(
      payload
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: billingSuscribePromise, isShowSuccess: true, isShowError: false },
      root
    );
    if(json?.type === 'card_error'){
      commit("shared/uiNotificationStatus", {
        status: "error",
        message: json.message,
        isOpen: true,
      }, { root: true });
      dispatch("shared/resetNotificationStatus", { root: true });
    }
    return {
      success,
      json,
    };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};

export const sendVideo = async ({ commit, dispatch }, { link }) => {
  try {
    const sendVideoPromise = await studentsServices.sendVideo(link);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: sendVideoPromise, isShowSuccess: true },
      root
    );
    return { success , json}
  } catch (ex) {
    //console.log(ex);
  } finally {
  }
};

export const billing = async ({ commit, dispatch }) => {
  try {
    const billingPromise = await studentsServices.billing();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: billingPromise, isShowSuccess: false },
      root
    );

    if (success) {
      commit(
        "students/billingSetHistory",
        { data: json.billing_history, 
          payment_date: json.payment_date, 
          isLoading: false },
        root
      );
    } else {
      commit(
        "students/billingSetHistory",
        { data: [], 
          payment_date: json.payment_date, 
          isLoading: false },
        root
      );
    }

    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
  }
};

export const cancelSubscription = async ({ commit, dispatch }) => {
  try {
    const cacncelSubscriptionPromise =
      await studentsServices.cancelSubscription();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: cacncelSubscriptionPromise, isShowSuccess: true },
      root
    );

    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};

export const addSubscription = async ({ commit, dispatch }, { payload }) => {
  try {
    const addSubscriptionPromise = await studentsServices.addSubscription(
      payload
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: addSubscriptionPromise, isShowSuccess: true },
      root
    );

    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};


export const pendingFeedbacks = async ({ commit, dispatch }) => {
  try {
    const addSubscriptionPromise = await studentsServices.pendingFeedbacks()
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: addSubscriptionPromise, isShowSuccess: false },
      root
    );
    commit("students/pendingFeedbacks", { data: json, isLoading: false }, root);
    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
    commit("students/billingTooglePayment", { isLoading: false }, root);
  }
};


export const timesAvailables = async ({ commit , dispatch}) => {
  try {
    const timesAvailablesPromise = await studentsServices.timesAvailables()
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: timesAvailablesPromise, isShowSuccess: false },
      root
    );
    // console.log(json,success)
    return { success, json };
  } catch (ex) {
    //console.log(ex);
  } finally {
  }
}