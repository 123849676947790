<template>
  <Dialog
    v-model:visible="notificationStatusInfo.isOpen"
    class="hidden-icons notification-status"
    :class="`status-${notificationStatusInfo?.status}`"
    :position="'bottomright'"
  >
    <div class="notification-content">
      <div></div>
      <p class="m-0 first-letter-uppercase" v-html="notificationStatusInfo.message" ></p>
      <i
        class="pi pi-times fs-5 fw-bold pointer"
        @click="closeNotificationStatusInfo"
      ></i>
    </div>
    <div
      class="
        notification-type
        d-flex
        gap-3
        align-items-center
        justify-content-center
      "
    >
      <img src="/assets/images/logo-icon-white.svg" />
      <p class="fs-5 first-letter-uppercase">{{ notificationStatusInfo.status }}</p>
    </div>
  </Dialog>
</template>

<script>
import useSharedUI from "@/shared/composables/useSharedUI";
import {onUpdated} from "vue"

export default {
  name: "NotificationStatusComponent",
  setup() {
    const { notificationStatusInfo, closeNotificationStatusInfo } =
      useSharedUI();
    onUpdated(() => {
      if(notificationStatusInfo.value.isOpen){
        setTimeout(() => closeNotificationStatusInfo(), 3000)
      }
    })
    return {
      notificationStatusInfo,
      closeNotificationStatusInfo,
    };
  },
};
</script>