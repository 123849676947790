export const memberStudentModal = (state) => {
  return state.modals.members.students;
};

export const memberEmailsModal = (state) => {
  return state.modals.members.sendEmails;
};

export const memberEmailResetModal = (state) => {
  return state.modals.members.resetEmails;
};

export const getMemberStudents = (state) => {
  return state.members.students;
};

export const memberTeacherModal = (state) => {
  return state.modals.members.teachers;
};

export const getMembersTeachers = (state) => {
  return state.members.teachers;
};

export const memberAdminModal = (state) => {
  return state.modals.members.admins;
};

export const getMembersAdmins = (state) => {
  return state.members.admins;
};

export const myLibraryModal = (state) => {
  return state.modals.mylibrary;
};

export const analyticsFinancialModal = (state) => {
  return state.modals.analytics.financial;
};

export const analyticsMarketingModal = (state) => {
  return state.modals.analytics.marketing;
};

export const adminInfo = (state) => {
  return state.adminInfo;
};

export const getDashbordStudents = (state) => {
  return state.dashboard.students;
};

export const getDashbordTeachers = (state) => {
  return state.dashboard.teachers;
};

export const getFinancial = (state) => {
  return state.analytics.financial;
};

export const getMarketing = (state) => {
  return state.analytics.marketing;
};


export const progressStudents = (state) => {
  return state.progressStudents;
}

export const myCalendar = (state) => {
  return state.myCalendar;
}

export const toggleModalCalendar = (state) => {
  return state.modals.myCalendar
}

export const upgradeLevel = (state) => {
  return state.modals.members.students.upgradeLevel
}

export const getRewardsApproval = (state) => {
  return state.analytics.marketing.rewards.approval;
}

export const getRewardsApproved = (state) => {
  return state.analytics.marketing.rewards.approved;
}

export const changeRole = (state) => {
  return state.modals.members.teachers.changeRole
}

export const getTeacher = (state) => {
  return state.members.teachers.teacher
}

export const getApproval = (state) => {
  return state.modals.analytics.marketing.approveRewards
}

export const previsualize = (state) => {
  return state.previsualize;
}

export const contentAddPayload = (state) => {
  return state.modals.mylibrary.contentAdd 
}

export const pendingTeachers = (state) => {
  return state.pendingTeachers;
}

export const teachersClassesDone = (state) => {
  return state.teachersClassesDone;
}

export const emailTemplates = (state) => {
  return state.emailTemplates
}

export const feedbackStudentOrTeacher = (state) => {
  return state.modals.members.feedback
}

export const exportChartsFinancial = (state) => {
  return state.modals.analytics.financial.exportCharts
}

export const exportChartsFinancialData = (state) => {
  return state.analytics.financial.exportCharts
}

export const scheduled = (state) => {
  return state.members.teachers.scheduled
}

export const toggleBlockTeacher = (state) => {
  return state.modals.members.teachers.blockTeacher
}