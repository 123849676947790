export const adminInfo = (state, payload) => {
  state.adminInfo = {
    ...state.adminInfo,
    ...payload,
  };
};

export const memberStudentsLeadModal = (state, payload) => {
  state.modals.members.students.lead = {
    ...state.modals.members.students.lead,
    ...payload,
  };
};

export const setStudentsBasicInfo = (state, payload) => {
  state.members.students.tables.basicInfo = {
    ...state.members.students.tables.basicInfo,
    ...payload,
  };
};

export const setTeacherModal = (state, payload) => {
  state.modals.members.teachers.addOrUpdate = {
    ...state.modals.members.teachers.addOrUpdate,
    ...payload,
  };
};

export const setTeachersBasicInfo = (state, payload) => {
  state.members.teachers.basicInfo = {
    ...state.members.teachers.basicInfo,
    ...payload,
  };
};

export const setTeachersAccount = (state, payload) => {
  state.members.teachers.account = {
    ...state.members.teachers.account,
    ...payload,
  };
};

export const setAdminModal = (state, payload) => {
  state.modals.members.admins.addOrUpdate = {
    ...state.modals.members.admins.addOrUpdate,
    ...payload,
  };
};

export const setAdminsBasicInfo = (state, payload) => {
  state.members.admins.basicInfo = {
    ...state.members.admins.basicInfo,
    ...payload,
  };
};

export const setAdminsAccount = (state, payload) => {
  state.members.admins.account = {
    ...state.members.admins.account,
    ...payload,
  };
};

export const setSendEmailModal = (state, payload) => {
  state.modals.members.sendEmails = {
    ...state.modals.members.sendEmails,
    ...payload,
  };
};

export const setResetEmailModal = (state, payload) => {
  state.modals.members.resetEmails = {
    ...state.modals.members.resetEmails,
    ...payload,
  };
};

export const toggleApproveRewardsModal = (state, isOpen) => {
  state.modals.analytics.marketing.approveRewards.isOpen = isOpen;
};

export const setDashboardStudents = (state, payload) => {
  state.dashboard.students = {
    ...state.dashboard.students,
    ...payload,
  };
};

export const setDashboardTeachers = (state, payload) => {
  state.dashboard.teachers = {
    ...state.dashboard.teachers,
    ...payload,
  };
};

export const setCurrentTableAdmins = (state, currentTable) => {
  state.members.admins.currentTable = currentTable;
};

export const setCurrentTableTeachers = (state, currentTable) => {
  state.members.teachers.currentTable = currentTable;
};

export const setFinancialLastPay = (state, payload) => {
  state.analytics.financial.lastPays = {
    ...state.analytics.financial.lastPays,
    ...payload,
  };
};

export const setFinancialTotal = (state, payload) => {
  state.analytics.financial.totals = {
    ...state.analytics.financial.totals,
    ...payload,
  };
};

export const progressStudents = (state, payload) => {
  state.progressStudents = {
    ...state.progressStudents,
    ...payload,
  };
};
export const setMarketingLastRegisters = (state, payload) => {
  state.analytics.marketing.lastRegisters = {
    ...state.analytics.marketing.lastRegisters,
    ...payload,
  };
};

export const setMarketingGraphRegisters = (state, payload) => {
  state.analytics.marketing.graphic = {
    ...state.analytics.marketing.graphic,
    ...payload,
  };
};

export const setCurrentTableRewards = (state, currentTable) => {
  state.analytics.marketing.rewards.currentTable = currentTable;
};

export const setMarketingApprovalRewards = (state, payload) => {
  state.analytics.marketing.rewards.approval = {
    ...state.analytics.marketing.rewards.approval,
    ...payload,
  };
};

export const setMarketingApprovedRewards = (state, payload) => {
  state.analytics.marketing.rewards.approved = {
    ...state.analytics.marketing.rewards.approved,
    ...payload,
  };
};

export const toggleModalContent = (state, payload) => {
  state.modals.mylibrary.contentAdd = {
    ...state.modals.mylibrary.contentAdd,
    ...payload,
  };
};

export const myCalendar = (state, payload) => {
  state.myCalendar = {
    ...state.myCalendar,
    ...payload,
  };
};

export const toggleModalCalendar = (state, payload) => {
  state.modals.myCalendar = {
    ...state.modals.myCalendar,
    ...payload,
  };
};

export const resetEmails = (state, payload) => {
  state.modals.members.resetEmails = {
    ...state.modals.members.resetEmails,
    ...payload,
  };
};

export const sendEmails = (state, payload) => {
  state.modals.members.sendEmails = {
    ...state.modals.members.sendEmails,
    ...payload,
  };
};

export const upgradeLevel = (state, payload) => {
  state.modals.members.students.upgradeLevel = {
    ...state.modals.members.students.upgradeLevel,
    ...payload,
  };
};

export const changeRole = (state, payload) => {
  state.modals.members.teachers.changeRole = {
    ...state.modals.members.teachers.changeRole,
    ...payload,
  };
};

export const setTeacher = (state, payload) => {
  state.members.teachers.teacher = {
    ...state.members.teachers.teacher,
    ...payload,
  };
};

export const setApproval = (state , payload) => {
  state.modals.analytics.marketing.approveRewards = {
    ...state.modals.analytics.marketing.approveRewards,
    ...payload,
  };
}

export const previsualize = (state, payload) => {
  state.previsualize = {
    ...state.previsualize,
    ...payload,
  };
}

export const contentAddPayload = (state, payload) => {
  state.modals.mylibrary.contentAdd = {
    ...state.modals.mylibrary.contentAdd,
    ...payload,
  };
}

export const pendingTeachers = (state, payload) => {
  state.pendingTeachers = {
    ...state.pendingTeachers,
    ...payload,
  };
}

export const teachersClassesDone = (state, payload) => {
  state.teachersClassesDone = {
    ...state.teachersClassesDone,
    ...payload,
  };
}


export const emailTemplates = (state,payload) => {
  state.emailTemplates = {
    ...state.emailTemplates,
    ...payload
  }
}

export const feedbackStudentOrTeacher = (state,payload) => {
  state.modals.members.feedback = {
    ...state.modals.members.feedback,
    ...payload
  }
}


export const exportChartsFinancial = (state,payload) => {
  state.modals.analytics.financial.exportCharts = {
    ...state.modals.analytics.financial.exportCharts,
    ...payload
  }
}

export const exportChartsFinancialData = (state,payload) => {
  state.analytics.financial.exportCharts = {
    ...state.analytics.financial.exportCharts,
    ...payload
  }
}

export const scheduled = (state,payload) => {
  state.members.teachers.scheduled = {
    ...state.members.teachers.scheduled,
    ...payload
  }
}

export const toggleBlockTeacher = (state,payload) => {
  state.modals.members.teachers.blockTeacher = {
    ...state.modals.members.teachers.blockTeacher,
    ...payload
  }
}