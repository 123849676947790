const uiMainItemsData = [
  {
    label: "Dashboard",
    icon: "home",
    name: "student-dashboard",
  },
  {
    label: "My library",
    icon: "library_books",
    name: "student-library",
  },
  {
    label: "My classes",
    icon: "school",
    name: "student-my-classes",
  },
  {
    label: "My teachers",
    icon: "people",
    name: "student-my-teachers",
  },
  {
    label: "Billing",
    icon: "payment",
    name: "student-billing",
  },
];

export default uiMainItemsData;
