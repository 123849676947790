export const userAuthentication = (state, payload) => {
  state.userAuthentication = {
    ...state.userAuthentication,
    ...payload,
  };
};

export const uiNotificationStatus = (state, payload) => {
  state.statusNotification = {
    ...state.statusNotification,
    ...payload,
  };
};

export const uiToggleLogoutModal = (state, isOpen) => {
  state.modals.logoutModalIsOpen = isOpen;
};

export const currentTime = (state, time) => {
  state.currentTime = time;
};

export const uiToggleConfirmModal = (state, isOpen) => {
  state.modals.confirmModal.confirmModalOpen = isOpen;
  if(isOpen) {
    state.modals.confirmModal.acceptRequest = false
  }
};
export const uiConfirmModalTitle = (state, title) => {
  state.modals.confirmModal.confirmModalTitle = title;
};
export const uiConfigModalQuestion = (state, question) => {
  state.modals.confirmModal.confirmModalQuestion = question;
};
export const uiConfigModalAccept = (state) => {
  state.modals.confirmModal.acceptRequest = true;
};

export const uiUpdateSidebarItems = (state, items) => {
  state.currentSidebar = items;
};

export const uiUserInfoModal = (state, payload) => {
  state.modals.userInfo = {
    ...state.modals.userInfo,
    ...payload,
  };
};

export const uiToggleNotificationClass = (state, payload) => {
  state.modals.notificationClass = {
    ...state.modals.notificationClass,
    ...payload,
  };
};

export const updateLibrary = (state, payload) => {
  state.library = {
    ...state.library,
    ...payload,
  };
};

export const updateCurrentLevel = (state, currentLevel) => {
  state.library = {
    ...state.library,
    ...currentLevel,
  };
};

export const updateNotificationList = (state, payload) => {
  state.modals.notificationList = {
    ...state.modals.notificationList,
    ...payload,
  };
};

export const setCalendarEvents = (state, payload) => {
  state.calendar.events = payload;
};

export const setCalendarEmail = (state, payload) => {
  state.calendar.userEmail = payload.userEmail;
  state.calendar.isLoggedIn = payload.isLoggedIn;
};

export const setClassBookedModal = (state, payload) => {
  state.modals.classBooked = {
    ...state.modals.classBooked,
    ...payload,
  };
};

export const setListing = (state, payload) => {
  state.listings = {
    ...state.listings,
    ...payload,
  };
};

export const setSubLevels = (state, payload) => {
  state.listings.levels.currentSubLevels = {
    ...state.listings.levels.currentSubLevels,
    ...payload,
  };
};

export const feedbackOrNoteConfirm = (state, payload) => {
  state.feedbackOrNoteConfirm = { 
    ...state.feedbackOrNoteConfirm,
    ...payload,
  }
}

export const menuHamburger = (state, payload) => {
  state.menuHamburger = {
    ...state.menuHamburger,
    ...payload,
  }
}