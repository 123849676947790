const getUserAuthenticated = () => {
  const LS_USER = JSON.parse(localStorage.getItem("bl-user")) ?? "";
  const hasRoleAndToken = LS_USER.role && LS_USER.token;
  const user = {};

  user.isLogged = false;
  if (hasRoleAndToken) {
    let role = LS_USER.role;
    // if (role === "coordinator") role = "admin";
    user.role = role;
    user.token = LS_USER.token;
    user.isLogged = true;
    user.is_new_student = LS_USER.is_new_student;
    user.has_random_password = LS_USER.has_random_password;
    user.completed_info = LS_USER.completed_info;
    user.timezone = LS_USER.timezone;

  }

  return user;
};

export default getUserAuthenticated;
