export default {
  name: "student",
  component: () =>
    import(
      /* webpackChunkName: "Private Layout" */ "@/shared/layouts/PrivateLayout.vue"
    ),
  children: [
    {
      path: "student-profile",
      name: "student-profile",
      component: () =>
        import(
          /* webpackChunkName: "Student Profile View" */ "@/modules/students/views/StudentProfileView.vue"
        ),
    },
    {
      path: "library/content",
      name: "student-library-content",
      component: () =>
        import(
          /* webpackChunkName: "My Library Level Content View" */ "@/shared/views/Library/LibraryLevelContentView.vue"
        ),
    },
    {
      path: "",
      name: "student-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Dashboard View" */ "@/modules/students/views/Dashboard/DashboardView.vue"
        ),
    },
    {
      path: "student-library",
      name: "student-library",
      component: () =>
        import(
          /* webpackChunkName: "My Library View" */ "@/modules/students/views/MyLibrary/MyLibraryView.vue"
        ),
    },
    {
      path: "student-my-classes",
      name: "student-my-classes",
      component: () =>
        import(
          /* webpackChunkName: "My Classes View" */ "@/modules/students/views/MyClasses/MyClassesView.vue"
        ),
    },
    {
      path: "student-my-classes/scheduled-class",
      name: "student-my-classes-scheduled-class",
      component: () =>
        import(
          /* webpackChunkName: "scheduled Class View" */ "@/shared/views/ScheduledClass/ScheduledClassView.vue"
        ),
    },
    {
      path: "student-my-teachers",
      name: "student-my-teachers",
      component: () =>
        import(
          /* webpackChunkName: "My Teachers View" */ "@/modules/students/views/MyTeachers/MyTeachersView.vue"
        ),
    },
    {
      path: "student-my-teachers/feedback",
      name: "student-teachers-feedback",
      component: () =>
        import(
          /* webpackChunkName: "My Teachers Feedback View" */ "@/modules/students/views/MyTeachers/MyTeachersFeedbackView.vue"
        ),
    },
    {
      path: "student-my-teachers/scheduled-class",
      name: "student-teachers-scheduled-class",
      component: () =>
        import(
          /* webpackChunkName: "scheduled Class View" */ "@/shared/views/ScheduledClass/ScheduledClassView.vue"
          ),
    },
    {
      path: "student-rewards",
      name: "student-rewards",
      component: () =>
        import(
          /* webpackChunkName: "Rewards View" */ "@/modules/students/views/Rewards/RewardsView.vue"
        ),
    },
    {
      path: "student-help",
      name: "student-help",
      component: () =>
        import(
          /* webpackChunkName: "Help View" */ "@/modules/students/views/Help/HelpView.vue"
        ),
    },
    {
      path: "student-billing",
      name: "student-billing",
      component: () =>
        import(
          /* webpackChunkName: "Billing View" */ "@/modules/students/views/Billing/BillingView.vue"
        ),
    },
    {
      path: ":pathMatch(.*)*",
      redirect: {
        name: "student-dashboard",
      },
    },
  ],
};
