import { apiWPService } from "@/core/services/apiWPService";
import { apiServiceWithToken } from "@/core/services/apiService";

const module = "user/";

export const getStudentInfo = async () => {
  const config = { endpoint: module + "panel/myperfil/myinfo" };
  return await apiServiceWithToken(config);
};

const getHelpQuestions = async () => {
  const config = { endpoint: "post-help?per_page=100" };
  return await apiWPService(config);
};

const getHelpCategories = async () => {
  const config = { endpoint: "categories" };
  return await apiWPService(config);
};

export const getRewardTypesDiscounts = async () => {
  const config = { endpoint: "discount-types" };
  return await apiWPService(config);
};

export const getRewardMyDiscountCode = async () => {
  const config = { endpoint: module + "panel/myperfil/mydiscountcode" };
  return await apiServiceWithToken(config);
};

export const getLevels = async () => {
  const config = {
    endpoint: `${module}panel/mylibrary/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

export const getStart = async () => {
  const config = {
    endpoint: `${module}panel/dashboard/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

export const getTeachersAll = async () => {
  const config = { endpoint: `${module}panel/myteacher/all` };
  return await apiServiceWithToken(config);
};

export const getClassesWeek = async () => {
  const config = { endpoint: `${module}panel/myclasses/myclassesweek` };
  return await apiServiceWithToken(config);
};

export const getTeachersFavorites = async () => {
  const config = { endpoint: `${module}panel/myteacher/favorites` };
  return await apiServiceWithToken(config);
};

export const getClassesDay = async () => {
  const config = { endpoint: `${module}panel/myclasses/myclassesday` };
  return await apiServiceWithToken(config);
};

export const getLastClass = async () => {
  const config = { endpoint: `${module}panel/myteacher/last_class` };
  return await apiServiceWithToken(config);
};

export const getFeedbacks = async () => {
  const config = { endpoint: `${module}panel/myteacher/feedbacks` };
  return await apiServiceWithToken(config);
};

export const editProfileStudent = async (payload) => {
  const config = {
    endpoint: `${module}panel/myperfil/editinfo`,
    method: "PUT",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const editPhotoStudent = async (image) => {
  const config = {
    endpoint: `${module}panel/myperfil/editphoto`,
    method: "POST",
    data: { photo: image },
    formData: true,
  };
  return await apiServiceWithToken(config);
};

export const markedCompleted = async (id) => {
  const config = {
    endpoint: `${module}panel/mylibrary/subtitle_mark/${id}`,
    method: "PUT",
    data: {},
  };
  return await apiServiceWithToken(config);
};

export const questionsTeachers = async () => {
  const config = { endpoint: `${module}panel/myteacher/question_student` };
  return await apiServiceWithToken(config);
};

export const sendFeedback = async (payload) => {
  const config = {
    endpoint: `${module}panel/myteacher/save_answer`,
    method: "POST",
    data: { answers: payload },
  };
  return await apiServiceWithToken(config);
};

export const linkGoogleEmail = async (email) => {
  const config = {
    endpoint: `${module}panel/myperfil/google_calendar_link`,
    method: "PUT",
    data: { email },
  };
  return await apiServiceWithToken(config);
};

export const unLinkGoogleEmail = async () => {
  const config = {
    endpoint: `${module}panel/myperfil/google_calendar_unlink`,
    method: "PUT",
    data: {},
  };
  return await apiServiceWithToken(config);
};

export const addRemoveFavorite = async (id) => {
  const config = {
    endpoint: `${module}panel/myteacher/favorite/${id}`,
    method: "PUT",
    data: {},
  };
  return await apiServiceWithToken(config);
};

export const cancelClass = async (id) => {
  const config = {
    endpoint: `${module}panel/myclasses/${id}`,
    method: "DELETE",
    data: {},
  };
  return await apiServiceWithToken(config);
};

export const availableTime = async (payload) => {
  const config = {
    endpoint: `${module}panel/myclasses/teachers/availity`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const createClassByTime = async (payload) => {
  const config = {
    endpoint: `${module}panel/myclasses/create`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const availablescheduleds = async (id) => {
  const config = {
    endpoint: `${module}panel/myclasses/teachers/${id}/availity`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

export const deleteCard = async (payload) => {
  const config = {
    endpoint: `${module}panel/myperfil/delete_card`,
    method: "DELETE",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const validateCard = async (payload) => {
  const config = {
    endpoint: `${module}panel/myperfil/validate_card`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const addCard = async (payload) => {
  const config = {
    endpoint: `${module}panel/myperfil/add_card`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const sendVideo = async (link_video) => {
  const config = {
    endpoint: `${module}panel/discounts/send-video`,
    method: "POST",
    data: { link_video },
  };
  return await apiServiceWithToken(config);
};

export const billing = async () => {
  const config = {
    endpoint: `${module}panel/billing/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

export const billingSuscribe = async (data) => {
  const config = {
    endpoint: `${module}panel/billing/subscribe`,
    method: "POST",
    data,
  };
  return await apiServiceWithToken(config);
};

export const cancelSubscription = async () => {
  const config = {
    endpoint: `${module}panel/myperfil/delete_suscription`,
    method: "DELETE",
    data: {},
  };
  return await apiServiceWithToken(config);
};

export const addSubscription = async (payload) => {
  const config = {
    endpoint: `${module}panel/billing/subscribe`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const pendingFeedbacks = async () => {
  const config = {
    endpoint: `${module}panel/myclasses/finished/withFeedBackFromTeacher`,
  };
  return await apiServiceWithToken(config);
}

export const timesAvailables = async () => {
  const config = {
    endpoint: `${module}panel/myclasses/teachers/availity/times`,
  }
  return await apiServiceWithToken(config);
}

export const pendingClasses = async () => {
  const config = {
    endpoint: `${module}panel/myclasses/myclassesweek/pending`,
  }
  return await apiServiceWithToken(config);
}

export const studentsServices = {
  pendingClasses,
  timesAvailables,
  pendingFeedbacks,
  addSubscription,
  cancelSubscription,
  billing,
  addCard,
  sendVideo,
  availablescheduleds,
  deleteCard,
  createClassByTime,
  availableTime,
  cancelClass,
  addRemoveFavorite,
  linkGoogleEmail,
  unLinkGoogleEmail,
  sendFeedback,
  questionsTeachers,
  markedCompleted,
  getFeedbacks,
  editPhotoStudent,
  editProfileStudent,
  getClassesWeek,
  getLastClass,
  getClassesDay,
  getTeachersFavorites,
  getHelpQuestions,
  getHelpCategories,
  getRewardTypesDiscounts,
  getRewardMyDiscountCode,
  getLevels,
  getStudentInfo,
  getStart,
  getTeachersAll,
  billingSuscribe,
  validateCard
};
