import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain:process.env.VUE_APP_AUTH_DOMAIN ,
  projectId:process.env.VUE_APP_PROJECT_ID ,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId:process.env.VUE_APP_MESSAGING_SENDER_ID ,
  appId:process.env.VUE_APP_APP_ID ,
  measurementId:process.env.VUE_APP_MEASUREMENT_ID,
  databaseURL:process.env.VUE_APP_DATABASE_URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app)

export default database;