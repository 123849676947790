import { createRouter, createWebHistory } from "vue-router";
import publicRouter from "@/modules/public/router";
import studentsRouter from "@/modules/students/router/studentsRouter";
import teachersRouter from "@/modules/teachers/router/teachersRouter";
import adminsRouter from "@/modules/admin/router";

import isAuthenticatedGuard from "@/core/router/privateGuard";

const routes = [
  {
    path: "/",
    ...publicRouter,
  },
  {
    path: "/students",
    ...studentsRouter,
    beforeEnter: [isAuthenticatedGuard],
  },
  {
    path: "/teachers",
    ...teachersRouter,
    beforeEnter: [isAuthenticatedGuard],
  },
  {
    path: "/admins",
    ...adminsRouter,
    beforeEnter: [isAuthenticatedGuard],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
