import { apiServiceWithToken } from "@/core/services/apiService";

const module = "teacher/";

const getDashboardStart = async () => {
  const config = {
    endpoint: `${module}panel/dashboard/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getLevels = async () => {
  const config = {
    endpoint: `${module}panel/mylibrary/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getStudents = async () => {
  const config = {
    endpoint: `${module}panel/mystudents/list`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getStudent = async (id) => {
  const config = {
    endpoint: `${module}panel/mystudents/showUser/${id}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const upgradeLevel = async (payload, id) => {
  const config = {
    endpoint: `${module}panel/mystudents/changeLevel/${id}`,
    method: "PUT",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const getClassesWeek = async () => {
  const config = { endpoint: `${module}panel/mycalendar/myClassesWeek` };
  return await apiServiceWithToken(config);
};

export const getClassesDay = async () => {
  const config = { endpoint: `${module}panel/mycalendar/myClassesToday` };
  return await apiServiceWithToken(config);
};

export const getAllClasses = async () => {
  const config = { endpoint: `${module}panel/mystudents/classes` };
  return await apiServiceWithToken(config);
};

export const resetPassword = async (payload) => {
  const config = { 
    endpoint: `${module}passwordUpdate`,
    method: "POST",
    data:payload
  };
  return await apiServiceWithToken(config);
};

export const questionsTeacher = async () => {
  const config = { endpoint: `${module}panel/mystudents/question_teacher` };
  return await apiServiceWithToken(config);
}

export const sendFeedback = async (payload) => {
  const config = {
    endpoint: `${module}panel/mystudents/save_answer`,
    method: "POST",
    data:payload
  }
  return await apiServiceWithToken(config);
}

export const coordinators = async () => {
  const config = {
    endpoint: `${module}panel/dashboard/coordinators`,
    method: "GET",
  }
  return await apiServiceWithToken(config);
}

export const finishClasses = () =>{
  const config = {
    endpoint: `${module}panel/mystudents/classes/finished`,
    method: "GET"
  }
  return apiServiceWithToken(config);
}

export const teacherServices = {
  finishClasses,
  coordinators,
  sendFeedback,
  questionsTeacher,
  getDashboardStart,
  getAllClasses,
  resetPassword,
  getClassesWeek,
  getLevels,
  upgradeLevel,
  getStudents,
  getStudent,
  getClassesDay,
};
