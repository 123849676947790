import { apiServiceWithToken } from "@/core/services/apiService";

export const getPhoneCodes = async () => {
  const config = { endpoint: "phone-codes" };
  return await apiServiceWithToken(config);
};

export const getLevels = async () => {
  const config = { endpoint: "levels-sublevels" };
  return await apiServiceWithToken(config);
};

export const getTimeZones = async () => {
  const config = { endpoint: "timezones" };
  return await apiServiceWithToken(config);
};

export const getSpanishKnowledge = async () => {
  const config = { endpoint: "spanish-knowledge" };
  return await apiServiceWithToken(config);
};

export const getLearningPath = async () => {
  const config = { endpoint: "learning-path" };
  return await apiServiceWithToken(config);
};

const emailTemplates = async () => {
  const config = { endpoint: `email-templates`}
  return await apiServiceWithToken(config)
}

const createTemplateEmail = async (payload) => {
  const config = {
    endpoint: `email-templates`,
    method: "POST",
    data: payload
  }
  return await apiServiceWithToken(config)
}

const levelsInfo = async () => {
  const config = { endpoint: `levels`}
  return await apiServiceWithToken(config)
}

const currentTime = async () => {
  const config = { endpoint: `time`}
  return await apiServiceWithToken(config)
}

export const sharedServices = {
  levelsInfo,
  createTemplateEmail,
  getPhoneCodes,
  emailTemplates,
  getLevels,
  getTimeZones,
  getSpanishKnowledge,
  getLearningPath,
  currentTime
};
