const baseUrl = process.env.VUE_APP_API_PROD + "/api/v1/";
import getUserAuthenticated from "@/shared/helpers/getUserAuthenticated";
// import { throwError } from "@vue/vue3-jest/lib/utils";
import store from "../store";

const root = { root: true };

const apiServiceNotToken = ({ endpoint, data, method = "GET" }) => {
  const url = `${baseUrl}${endpoint}`;

  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      mode: "cors",

      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    });
  }
};

const validateToken = async ({ url, requestOptions }) => {
  const response = await fetch(url, requestOptions);
  const { success } = await store.dispatch(
    "shared/validateUserToken",
    { response },
    root
  );
  if (success) return response;
  else
    return new Promise(function () {
      throw new Error("A system error has occurred");
    });
};

const apiServiceWithToken = async ({
  endpoint,
  data,
  method = "GET",
  formData = false,
}) => {
  const url = `${baseUrl}${endpoint}`;
  const user = getUserAuthenticated();
  const token = user.token ?? "";

  /* *** CONFIGURATIONS ----------- */
  let headers = {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };

  let requestOptions = { method, headers };

  if (method === "GET") {
    return await validateToken({ url, requestOptions });
  } else {
    headers["Content-type"] = "application/json";
    requestOptions.body = JSON.stringify(data);

    if (formData === false && Object.keys(data).length === 0)
      delete requestOptions.body;

    if (formData) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      delete requestOptions.headers;
      delete requestOptions.body;
      const formData = new FormData();
      for (const name in data) {
        Array.isArray(data[name])
        ? data[name].forEach(value => formData.append(name + '[]', value))
        : formData.append(name, data[name]) ;
      }
      requestOptions.headers = myHeaders;
      requestOptions.body = formData;
    }
    return await validateToken({ url, requestOptions });
  }
};

export { apiServiceNotToken, apiServiceWithToken };
