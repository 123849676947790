export const studentInformation = (state, student) => {
  state.student = {
    ...state.student,
    ...student,
  };
};

export const profilePersonalDetailsStatus = (state, payload) => {
  state.profile.details.status = {
    ...state.profile.details.status,
    ...payload,
  };
};
export const billingTooglePayment = (state, paylaod) => {
  state.billing.payment = {
    ...state.billing.payment,
    ...paylaod,
  };
};

export const billingSetHistory = (state, payload) => {
  state.billing.history = {
    ...state.billing.history,
    ...payload,
  };
};

export const billingToogleDeleteModal = (state, payload) => {
  state.modals.billing.deleteCard = {
    ...state.modals.billing.deleteCard,
    ...payload,
  };
};

export const billingToogleSuscriptionDeleteModal = (state, payload) => {
  state.modals.billing.deleteSuscription = {
    ...state.modals.billing.deleteSuscription,
    ...payload,
  };
};

export const teacherToggleInfoModal = (state, payload) => {
  state.modals.teacher.info = {
    ...state.modals.teacher.info,
    ...payload,
  };
};

export const setTeachersAll = (state, teachers) => {
  state.teachers.all = {
    ...state.teachers.all,
    ...teachers,
  };
};

export const setTeachersAllAvailable = (state, teachers) => {
  state.teachers.available = {
    ...state.teachers.available,
    ...teachers,
  };
};

export const setTeachersFavorites = (state, teacherFavorites) => {
  state.teachers.favorites = {
    ...state.teachers.favorites,
    ...teacherFavorites,
  };
};

export const studentToggleLastTeacherModal = (state, payload) => {
  state.modals.student.lastTeacher = {
    ...state.modals.student.lastTeacher,
    ...payload,
  };
};

export const helpQuestions = (state, categoryQuestions) => {
  state.help = {
    ...state.help,
    isLoading: false,
    categoryQuestions,
  };
};

export const helpSelectedCategory = (state, currentCategory) => {
  state.help.searchResults = {
    ...state.help.searchResults,
    ...currentCategory,
  };
};

export const helpIsSearching = (state, isSearching) => {
  state.help.isSearching = isSearching;
};

export const helpCurrentQuestionIds = (state, idQuestion) => {
  state.help.searchResults.currentQuestionIds = [
    ...state.help.searchResults.currentQuestionIds,
    idQuestion,
  ];
};

export const helpUpdateQuestionIds = (state, questionIds) => {
  state.help.searchResults.currentQuestionIds = questionIds;
};

export const helpSearching = (state, text) => {
  const categories = state.help.categoryQuestions;
  const results = [];

  if (text) {
    const valueText = text.toLowerCase();
    for (let [_, value] of Object.entries(categories)) {
      const question = value.questions.filter((q) => {
        const question_ = q.question.toLowerCase();
        const answer = q.answer.toLowerCase();
        if (question_.includes(valueText) || answer.includes(valueText))
          return q;
      })[0];

      if (question) results.push(question);
    }
  }
  state.help.searchResults.questions = results;

  if (results.length === 0) {
    state.help.searchResults = {
      ...state.help.searchResults,
      currentCategoriesIds: [],
      currentQuestionIds: [],
    };
  } else {
    const firstQuestionId = results[0].id;
    const categoriesIds = [...new Set(results.map((q) => q.categoryID))];

    state.help.searchResults.currentQuestionIds = [firstQuestionId];
    state.help.searchResults.currentCategoriesIds = categoriesIds;
  }
};

export const helpResetSearching = (state) => {
  state.help.searchResults = {
    ...state.help.searchResults,
    currentCategoriesIds: [],
    currentQuestionIds: [],
    questions: [],
  };
};

export const rewardTypesDiscounts = (state, payload) => {
  state.rewards = {
    ...state.rewards,
    ...payload,
  };
};

export const rewardDiscountCode = (state, code) => {
  state.rewards.discountCode = code;
};

export const toggleTerms = (state, isOpen) => {
  state.modals.rewards.terms.isOpen = isOpen;
};

export const logout_student = (state, payload) => {
  state.student.logout_student = payload;
};

export const dashboardStart = (state, payload) => {
  state.dashboard = {
    ...state.dashboard,
    ...payload,
  };
};

export const classesTakenThisWeek = (state, payload) => {
  state.student.classes.takenThisWeek = {
    ...state.student.classes.takenThisWeek,
    ...payload,
  };
};

export const classesTakenThisDay = (state, payload) => {
  state.student.classes.takenThisDay = {
    ...state.student.classes.takenThiDay,
    ...payload,
  };
};

export const studentClassesInCalendar = (state, payload) => {
  state.student.classes.isCalendar = {
    ...state.student.classes.isCalendar,
    ...payload,
  };
};

export const lastClass = (state, payload) => {
  state.teachers.lastClass = {
    ...state.teachers.lastClass,
    ...payload,
  };
};

export const feedback = (state, payload) => {
  state.feedback = payload;
};

export const teacherModal = (state, payload) => {
  state.teacherModal = payload;
};

export const feedbacks = (state, payload) => {
  state.feedbacks = payload;
};

export const classesThisWeekPending = (state, payload) => {
  state.student.classes.classesThisWeekPending = {
    ...state.student.classes.classesThisWeekPending,
    ...payload,
  };
};

export const questions = (state, payload) => {
  state.questions = {
    ...state.questions,
    ...payload,
  };
};

export const scheduledClass = (state, payload) => {
  state.scheduledClass = {
    ...state.scheduledClass,
    ...payload,
  };
};

export const availableTeachers = (state, payload) => {
  state.availableTeachers = {
    ...state.availableTeachers,
    ...payload,
  };
};

export const availablescheduleds = (state, payload) => {
  state.availablescheduleds = {
    ...state.availablescheduleds,
    ...payload,
  };
};

export const teachersClass = (state, payload) => {
  state.teachersClass = {
    ...state.teachersClass,
    ...payload,
  };
};

export const infoClassModal = (state, payload) => {
  state.infoClassModal = {
    ...state.infoClassModal,
    ...payload,
  };
};

export const teacherSelect = (state, payload) => {
  state.teacherSelect = {
    ...state.teacherSelect,
    ...payload,
  };
};

export const formCard = (state, payload) => {
  state.formCard = {
    ...state.formCard,
    ...payload,
  };
};

export const startTour = (state,payload) => {
  state.startTour = payload;
}

export const pendingFeedbacks = (state,payload) => {
  state.pendingFeedbacks = payload;
}

export const confirmTour = (state,payload) => {
  state.modals.student.confirmTour = payload;
}
export const setStudentCard = (state,payload) => {
  state.student.card = payload;
}

export const cancelClass = (state,payload) => {
  state.modals.student.cancelClass = {
    ...state.modals.student.cancelClass,
    ...payload,
  };
}

export const deleteFavorite = (state,payload) => {
  state.modals.teacher.deleteFavorite = {
    ...state.modals.teacher.deleteFavorite,
    ...payload,
  };
}




