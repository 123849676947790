export const studentIsShowUpgradeLevel = (state) => {
  return state.modals.student.updateLevel.isOpen;
};

export const studentIsShowFeedback = (state) => {
  return state.modals.student.feedback;
};

export const teacherInfo = (state) => {
  return state.teacher.info;
};

export const teacherClassesTaughtThisWeek = (state) => {
  return state.teacher.classes.takenThisWeek;
};

export const teacherClassesCalendar = (state) => {
  return state.teacher.classes.inCalendar;
};

export const teacherClassesForDay = (state) => {
  return state.teacher.classes.forDay;
};


export const getStudents = (state) => {
  return state.students;
};

export const getStudent = (state) => {
  return state.student;
};


export const dashboardStart = (state) => {
  return state.dashboard
}

export const classModal = (state) => {
  return state.classModal
}

export const allClasses = (state) => {
  return state.teacher.classes.allClasses
}

export const toggleModalResetPassword = (state) => {
  return state.modals.student.resetPassword
}

export const questions = (state) => {
  return state.questions
}

export const coordinators = (state) => {
  return state.coordinators
}

export const finishClasses = (state) => {
  return state.teacher.classes.finishedClasses
}

export const teacherNote = (state) => {
  return state.modals.student.teacherNote
}