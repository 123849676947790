const menuItems = [
  {
    label: "Dashboard",
    icon: "home",
    name: "admin-dashboard",
  },
  {
    label: "Members",
    icon: "groups",
    name: "admin-members",
    childrens: [
      {
        label: "Students",
        name: "admin-members-students",
        icon: "home",
      },
      {
        label: "Teachers",
        name: "admin-members-teachers",
        icon: "home",
      },
      {
        label: "Admins",
        name: "admin-members-admins",
        icon: "home",
      },
    ],
  },
  {
    label: "My library",
    icon: "library_books",
    name: "admin-library",
  },
  {
    label: "My calendar",
    icon: "calendar_today",
    name: "admin-calendar",
  },
  {
    label: "Analytics",
    icon: "insights",
    name: "admin-analytics",
    childrens: [
      {
        label: "Financial",
        name: "admin-analytics-financial",
      },
      {
        label: "Marketing",
        name: "admin-analytics-marketing",
      },
    ],
  },
];

export default menuItems;
