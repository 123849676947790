import { adminsServices } from "@/modules/admin/services/adminsServices";
import { studentsServices } from "../../students/services/studentsServices";
import { sharedServices } from "../../../shared/services/sharedServices";
import { ref as refDb, set, onValue } from "firebase/database";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { getFollowedClasses } from "@/shared/helpers/getFollowedClasses";
import database from "@/firebase/config";
const root = { root: true };

export const dashboardStart = async ({ commit }) => {
  try {
    commit("admins/adminInfo", { isLoading: true }, root);
    const dashboardStartPromise = await adminsServices.getDashboardStart();
    if (dashboardStartPromise) {
      const res = await dashboardStartPromise.json();
      let {
        admin_info,
        students,
        teachers,
        total_lessons_month,
        total_lessons_week,
        total_users,
      } = res.data;

      if (admin_info.token_firebase) {
        const dbRef = refDb(
          database,
          "Admins/" + admin_info.token_firebase + "/last_status"
        );
        onValue(dbRef, async (snapshot) => {
          let last_status = snapshot.val();
          let status_set;
          if (last_status === "offline") status_set = "online";
          else status_set = last_status;

          set(
            refDb(database, "Admins/" + admin_info.token_firebase + "/status"),
            "online"
          );

          commit(
            "shared/userAuthentication",
            {
              status: status_set,
              last_status: last_status,
              token_firebase: admin_info.token_firebase,
            },
            root
          );
        });
      }
      commit(
        "admins/adminInfo",
        {
          ...admin_info,
          total_lessons_month,
          total_lessons_week,
          total_users,
          isLoading: false,
        },
        root
      );
      commit("students/studentInformation", { isLoading: false }, root);

      commit(
        "admins/setDashboardStudents",
        { data: students, isLoading: false },
        root
      );
      commit(
        "admins/setDashboardTeachers",
        { data: teachers, isLoading: false },
        root
      );
    }
  } catch (ex) {
    // //console.log(ex);
  } finally {
    commit("admins/setDashboardStudents", { isLoading: false }, root);
    commit("admins/setDashboardTeachers", { isLoading: false }, root);
    commit("admins/adminInfo", { isLoading: false }, root);
  }
};

export const getStudents = async ({ commit }, page) => {
  console.log(page);
  commit("admins/setStudentsBasicInfo", { isLoading: true, data: [] }, root);
  try {
    const getStudentsPromise = await adminsServices.getStudentsStart(page);
    if (getStudentsPromise) {
      const students = await getStudentsPromise.json();
      let basicInfo = [];

      if (students.data.length > 0)
        basicInfo = students.data.map((student) => {
          if (student.birth)
            student.birth = formatDate(student.birth, "american");
          if (student.enrollment_date)
            student.enrollmentDate = formatDate(
              student.enrollment_date,
              "american"
            );
          if (student.payment_date)
            student.payment_date = formatDate(student.payment_date, "american");
          if (student.first_payment_date)
            student.firstPaymentDate = formatDate(
              student.first_payment_date,
              "american"
            );
          student.canceled = student.canceled_lessons_count.toString();
          student.effective = student.effective_lessons_count.toString();
          student.code = student.discount_codes_count.toString();
          student.video = student.discount_video_count.toString();
          student.paymentMethod = student.payment_method.toString();

          student.status = "offline";
          return student;
        });
      commit(
        "admins/setStudentsBasicInfo",
        { data: basicInfo, meta: students },
        root
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setStudentsBasicInfo", { isLoading: false }, root);
  }
};

export const getStudentsByReport = async ({ commit }, [report, page = 1]) => {
  commit("admins/setStudentsBasicInfo", { isLoading: true, data: [] }, root);
  try {
    const getStudentsPromise = await adminsServices.getStudentsStartByReport(
      report,
      page
    );
    if (getStudentsPromise) {
      const students = await getStudentsPromise.json();
      let basicInfo = [];
      if (students.data.length > 0)
        basicInfo = students.data.map((student) => {
          student.paymentMethod = student.payment_method?.toString();
          if (student.birth)
            student.birth = formatDate(student.birth, "american");
          if (student.enrollment_date)
            student.enrollmentDate = formatDate(
              student.enrollment_date,
              "american"
            );
          if (student.payment_date)
            student.payment_date = formatDate(student.payment_date, "american");
          if (student.first_payment_date)
            student.firstPaymentDate = formatDate(
              student.first_payment_date,
              "american"
            );
          return student;
        });
      commit(
        "admins/setStudentsBasicInfo",
        { data: basicInfo, meta: students },
        root
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setStudentsBasicInfo", { isLoading: false }, root);
  }
};

export const getTeachers = async ({ commit }) => {
  try {
    const getTeachersPromise = await adminsServices.getTeachersStart();
    if (getTeachersPromise) {
      let res = await getTeachersPromise.json();
      let basicInfo = [];
      let account = [];

      if (res.length > 0) {
        res = res.map((teacher) => {
          if (teacher.birth)
            teacher.birth = formatDate(teacher.birth, "american");
          return teacher;
        });
        basicInfo = res;
        account = res;
      }
      commit("admins/setTeachersBasicInfo", { data: basicInfo }, root);
      commit("admins/setTeachersAccount", { data: account }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setTeachersBasicInfo", { isLoading: false }, root);
    commit("admins/setTeachersAccount", { isLoading: false }, root);
  }
};

export const updateTeacher = async ({ dispatch }, { id, data }) => {
  data._method = "PUT";
  try {
    const updateTeacherPromise = await adminsServices.postUpdateTeacher({
      id,
      data,
    });

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: updateTeacherPromise, isShowSuccess: true },
      root
    );

    if (success) {
      dispatch("admins/getTeacher", { id }, root);
    }
    return {
      success,
      json,
    };
  } catch (error) {
    console.error(error);
  }
};

export const updateJobTeacher = async ({ dispatch }, { id, data }) => {
  data._method = "PUT";
  try {
    const updateJobTeacherPromise = await adminsServices.updateJobTeacher({
      id,
      data,
    });
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: updateJobTeacherPromise, isShowSuccess: true },
      root
    );
    if (success) {
      dispatch("admins/getTeacher", { id }, root);
    }
    return {
      success,
      json,
    };
  } catch (error) {
    // //console.log(error);
  }
};
/* updateJobTeacher */

export const getTeachersByReport = async ({ commit }, report) => {
  commit("admins/setTeachersBasicInfo", { isLoading: true, data: [] }, root);
  try {
    const getTeachersPromise = await adminsServices.getTeachersStartByReport(
      report
    );
    if (getTeachersPromise) {
      const teachers = await getTeachersPromise.json();
      let basicInfo = [];

      if (teachers.length > 0) {
        basicInfo = teachers.map((teacher) => {
          if (teacher.birth)
            teacher.birth = formatDate(teacher.birth, "american");
          return teacher;
        });
      }
      commit("admins/setTeachersBasicInfo", { data: basicInfo }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setTeachersBasicInfo", { isLoading: false }, root);
  }
};

export const getAdmins = async ({ commit }) => {
  try {
    const getAdminsPromise = await adminsServices.getAdminsStart();
    if (getAdminsPromise) {
      const res = await getAdminsPromise.json();
      let basicInfo = [];
      let account = [];

      if (res.length > 0) {
        basicInfo = res.map((admin) => {
          if (admin.birth) admin.birth = formatDate(admin.birth, "american");
          return admin;
        });
        account = res;
      }

      commit("admins/setAdminsBasicInfo", { data: basicInfo }, root);
      commit("admins/setAdminsAccount", { data: account }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setAdminsBasicInfo", { isLoading: false }, root);
    commit("admins/setAdminsAccount", { isLoading: false }, root);
  }
};

export const createLead = async ({ commit, dispatch }, data) => {
  try {
    const createLeadPromise = await adminsServices.postCreateLead(data);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: createLeadPromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/memberStudentsLeadModal", { save: false }, root);
  }
};

export const createTeacher = async ({ dispatch }, data) => {
  try {
    const createTeacherPromise = await adminsServices.postCreateTeacher(data);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: createTeacherPromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    console.error(error);
  }
};

export const financialStart = async ({ commit }) => {
  try {
    const financialStartPromise = await adminsServices.getFinancialStart();
    if (financialStartPromise) {
      const {
        last_pays,
        new_clients,
        total_sales,
        users_today,
        money_today,
        total_pays_month,
        total_pays_week,
      } = await financialStartPromise.json();
      if (last_pays.length > 0)
        commit("admins/setFinancialLastPay", { data: last_pays }, root);
      commit(
        "admins/setFinancialTotal",
        {
          data: {
            users_today,
            total_sales,
            money_today,
            total_pays_month,
            total_pays_week,
            new_clients,
          },
        },
        root
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setFinancialLastPay", { isLoading: false }, root);
    commit("admins/setFinancialTotal", { isLoading: false }, root);
  }
};

export const createAdmin = async ({ dispatch }, data) => {
  try {
    const createAdminPromise = await adminsServices.postCreateAdmin(data);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: createAdminPromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    console.error(error);
  }
};

export const getAdmin = async ({ dispatch, commit }, id) => {
  try {
    const getAdminPromise = await adminsServices.getAdminById(id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: getAdminPromise },
      root
    );
    commit("admins/setAdminModal", { editToAdmin: success ? json : {} }, root);
  } catch (error) {
    // //console.log(error);
  } finally {
    commit("admins/setAdminModal", { loadingInfo: false }, root);
  }
};

export const updateAdmin = async ({ dispatch }, { id, data }) => {
  try {
    const updateAdminPromise = await adminsServices.postUpdateAdmin({
      id,
      data,
    });
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: updateAdminPromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    // //console.log(error);
  }
};

export const validateZoom = async ({ dispatch }, { email }) => {
  try {
    const validateZoomPromise = await adminsServices.validateZoom(email);
    let { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: validateZoomPromise },
      root
    );
    return { success, json };
  } catch (error) {
    return { status: "error" };
  }
};

export const getLibrary = async (
  { commit, getters },
  { levelIdUpdate = null }
) => {
  try {
    const getLibraryPromise = await adminsServices.getLibrary();
    const { contentAdd } = getters.myLibraryModal;
    if (getLibraryPromise) {
      const res = await getLibraryPromise.json();
      const levels = res.data;
      const currentLevel = contentAdd.isUpdate
        ? levels.find((level) => level.level_id === levelIdUpdate)
        : levels[0];
      if (contentAdd.isUpdate) {
        commit(
          "shared/updateLibrary",
          {
            levels,
            currentLevel,
            isLoading: false,
          },
          root
        );
        commit("admins/contentAddPayload", { isUpdate: false }, root);
      } else {
        commit(
          "shared/updateLibrary",
          {
            levels,
            currentLevel,
            isLoading: false,
            currentLevelNext: levels[1],
          },
          root
        );
      }
      commit(
        "admins/progressStudents",
        { data: res.general_progress, isLoading: false },
        root
      );
    } else {
      commit("admins/progressStudents", { data: {}, isLoading: false }, root);
    }
  } catch (error) {
    console.error(error);
    commit("admins/progressStudents", { data: {}, isLoading: false }, root);
  }
};

export const marketingRegistersStart = async ({ commit }) => {
  try {
    const registersStartPromise =
      await adminsServices.getMarketingRegistersStart();
    if (registersStartPromise) {
      const res = await registersStartPromise.json();
      let { last_registers, total_registers_month, total_registers_week } = res;
      if (last_registers.length > 0) {
        commit(
          "admins/setMarketingLastRegisters",
          { data: last_registers },
          root
        );
      }
      commit(
        "admins/setMarketingGraphRegisters",
        { data: { total_registers_month, total_registers_week } },
        root
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setMarketingLastRegisters", { isLoading: false }, root);
    commit("admins/setMarketingGraphRegisters", { isLoading: false }, root);
  }
};

export const marketingRewardsApproved = async ({ commit }) => {
  try {
    const rewardsApprovedPromise =
      await adminsServices.getMarketingRewardsApprovedStart();
    if (rewardsApprovedPromise) {
      let data = await rewardsApprovedPromise.json();

      if (data.length > 0) {
        data = data.map((user) => {
          if (user.claimend)
            user.claimend = formatDate(user.claimend, "american");
          return user;
        });
      } else {
        commit("admins/setMarketingApprovedRewards", { data: [] }, root);
      }
      commit("admins/setMarketingApprovedRewards", { data }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setMarketingApprovedRewards", { isLoading: false }, root);
  }
};

export const marketingRewardsApproval = async ({ commit }) => {
  try {
    const rewardsApprovalPromise =
      await adminsServices.getMarketingRewardsApprovalStart();
    if (rewardsApprovalPromise) {
      let data = await rewardsApprovalPromise.json();
      if (data.length > 0) {
        data = data.map((user) => {
          if (user.claimend)
            user.claimend = formatDate(user.claimend, "american");
          if (user.renewal) user.renewal = formatDate(user.renewal, "american");
          return user;
        });
      } else {
        commit("admins/setMarketingApprovalRewards", { data: [] }, root);
      }
      commit("admins/setMarketingApprovalRewards", { data }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setMarketingApprovalRewards", { isLoading: false }, root);
  }
};

export const marketingRewardsApprovalById = async (_, id) => {
  try {
    const rewardsApprovalPromise =
      await adminsServices.getMarketingRewardsApprovalById({ id });
    const res = {
      success: false,
      json: {},
    };
    if (rewardsApprovalPromise) {
      res.json = await rewardsApprovalPromise.json();
      res.success = true;
    }
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const myCalendar = async ({ commit }) => {
  try {
    const myCalendarPromise = await adminsServices.calendar();
    if (myCalendarPromise) {
      const data = await myCalendarPromise.json();
      commit("admins/myCalendar", { isLoading: false, data }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
    commit("admins/setMarketingApprovalRewards", { isLoading: false }, root);
  }
};

export const sendEmailReset = async ({ commit, dispatch }, { payload }) => {
  try {
    const sendEmailsResetPromise = await adminsServices.sendResetEmails(
      payload
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: sendEmailsResetPromise, isShowSuccess: true },
      root
    );
    if (success) {
      commit("admins/setResetEmailModal", { isOpen: false }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const getStudentById = async (_, id) => {
  try {
    const studentPromise = await adminsServices.getStudentById(id);
    if (studentPromise) {
      const json = await studentPromise.json();

      return { success: true, json };
    }
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

export const updateStudentLead = async ({ dispatch }, { id, payload }) => {
  try {
    const res = {
      success: false,
      json: {},
    };
    const studentPromise = await adminsServices.updateStudentLead(payload, id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: studentPromise, isShowSuccess: true },
      root
    );
    if (success) {
      // const json = await studentPromise.json();
      res.json = json;
      res.success = true;
    }

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getStudentPaymentById = async (_, { id }) => {
  try {
    const studentPromise = await adminsServices.getStudentPaymentById(id);
    if (studentPromise) {
      const json = await studentPromise.json();

      return { success: true, json };
    }
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

export const sendEmails = async ({ commit, dispatch }, { payload }) => {
  try {
    const sendEmailsPromise = await adminsServices.sendEmails(payload);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: sendEmailsPromise, isShowSuccess: true },
      root
    );
    if (success) {
      commit("admins/setSendEmailModal", { isOpen: false }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const saveTemplate = async ({ commit, dispatch }, { payload }) => {
  try {
    const saveTemplatePromise = await adminsServices.saveTemplate(payload);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: saveTemplatePromise, isShowSuccess: true },
      root
    );
    if (success) {
      // commit("admins/setSendEmailModal", { emails: [], isOpen: false }, root);
    }
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const getTeacher = async ({ commit, dispatch }, { id }) => {
  try {
    const getTeacherByIdPromise = await adminsServices.getTeacherById(id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: getTeacherByIdPromise, isShowSuccess: false },
      root
    );
    if (success) {
      json.history_lessons = getFollowedClasses(json.history_lessons);
      commit("admins/setTeacher", { data: json }, root);
    }
    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const changeRole = async ({ commit, dispatch }, { role_id, id }) => {
  try {
    const changeRolePromise = await adminsServices.changeRole({ role_id }, id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: changeRolePromise, isShowSuccess: true },
      root
    );

    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const approveReward = async (
  { commit, dispatch },
  { payload, id, show }
) => {
  try {
    const approveReward = await adminsServices.approveReward(payload, id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: approveReward, isShowSuccess: show },
      root
    );

    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const upgradeStudent = async ({ commit, dispatch }, { payload, id }) => {
  try {
    const changeRolePromise = await adminsServices.upgradeStudent(payload, id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: changeRolePromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const createContentLibrary = async (
  { commit, dispatch },
  { payload }
) => {
  try {
    const createContentLibrary = await adminsServices.createContentLibrary(
      payload
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: createContentLibrary, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const updateContentLibrary = async (
  { commit, dispatch },
  { payload, id }
) => {
  try {
    const changeRolePromise = await adminsServices.updateContentLibrary(
      payload,
      id
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: changeRolePromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const deleteContentLibrary = async (
  { commit, dispatch },
  { payload, id }
) => {
  try {
    const changeRolePromise = await adminsServices.deleteContentLibrary(
      payload,
      id
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: changeRolePromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    // console.log(error);
  } finally {
  }
};

export const getPendingFeedbacks = async ({ commit, dispatch }) => {
  try {
    const pendingFeedbacksPromise = await adminsServices.getPendingFeedbacks();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: pendingFeedbacksPromise, isShowSuccess: false },
      root
    );
    if (success) {
      commit(
        "admins/pendingTeachers",
        { data: json.data, isLoading: false },
        root
      );
    }
    return { success, json };
  } catch (error) {
    // //console.log(error);
  } finally {
  }
};

export const getTeachersClassesDone = async ({ commit, dispatch }) => {
  try {
    const getTeachersClassesDonePromise =
      await adminsServices.getTeachersClassesDone();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: getTeachersClassesDonePromise, isShowSuccess: false },
      root
    );
    if (success) {
      commit(
        "admins/teachersClassesDone",
        { data: json.data, isLoading: false },
        root
      );
    }
    return { success, json };
  } catch (error) {
    //console.log(error);
  } finally {
  }
};

export const emailTemplates = async ({ commit }) => {
  try {
    const emailTemplatesPromise = await sharedServices.emailTemplates();
    const emailTemplatesResp = await emailTemplatesPromise.json();
    // console.log(emailTemplatesResp)
    commit(
      "admins/emailTemplates",
      { isLoading: false, data: emailTemplatesResp },
      root
    );
  } catch (error) {
    //console.log(error)
  } finally {
  }
};

export const filterReportFinancial = async (
  { commit, dispatch },
  { payload }
) => {
  try {
    const filterReportFinancialPromise =
      await adminsServices.filterReportFinancial(payload);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: filterReportFinancialPromise, isShowSuccess: false },
      root
    );
    return { success, json };
  } catch (error) {
  } finally {
  }
};

export const workScheduleTeacher = async ({ dispatch }, { payload, id }) => {
  try {
    const workScheduleTeacherPromise = await adminsServices.getScheduleWork(
      payload,
      id
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: workScheduleTeacherPromise, isShowSuccess: false },
      root
    );
    return { success, json };
  } catch (error) {
  } finally {
  }
};

export const saveWorkSchedule = async ({ dispatch }, { payload, id }) => {
  try {
    const saveWorkSchedulePromise = await adminsServices.saveSchedule(
      payload,
      id
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: saveWorkSchedulePromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
  } finally {
  }
};

export const blockTeacher = async ({ dispatch }, { id }) => {
  try {
    const blockTeacherPromise = await adminsServices.blockTeacher(id);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: blockTeacherPromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    console.log(error);
  } finally {
  }
};

export const availableTeachers = async ({ commit, dispatch }, { payload }) => {
  try {
    const availableTimePromise = await studentsServices.availableTime(payload);
    if (availableTimePromise) {
      const availableTimeJSON = await availableTimePromise.json();

      return availableTimeJSON;
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const changeTeacher = async (
  { dispatch },
  { idLesson, idNewTeacher }
) => {
  try {
    const changeTeacherPromise = await adminsServices.changeTeacher(
      idLesson,
      idNewTeacher
    );
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: changeTeacherPromise, isShowSuccess: true },
      root
    );
    return { success, json };
  } catch (error) {
    console.log(error);
  } finally {
  }
};
