import { createStore } from "vuex";
import shared from "@/shared/store";
import auth from "@/modules/public/store";

import admins from "@/modules/admin/store";
import teachers from "@/modules/teachers/store";
import students from "@/modules/students/store";

export default createStore({
  modules: { shared, auth, admins, teachers, students },
});
