export default {
  name: "teacher",
  component: () =>
    import(
      /* webpackChunkName: "Private Layout" */ "@/shared/layouts/PrivateLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "teacher-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Dashboard View" */ "@/modules/teachers/views/Dashboard/DashboardView.vue"
        ),
    },
    {
      path: "library",
      name: "teacher-library",
      component: () =>
        import(
          /* webpackChunkName: "My Library View" */ "@/modules/teachers/views/MyLibrary/MyLibraryView.vue"
        ),
    },
    {
      path: "library/content",
      name: "teacher-library-content",
      component: () =>
        import(
          /* webpackChunkName: "My Library Level Content View" */ "@/shared/views/Library/LibraryLevelContentView.vue"
        ),
    },
    {
      path: "students",
      name: "teacher-students",
      component: () =>
        import(
          /* webpackChunkName: "My Students View" */ "@/modules/teachers/views/Students/MyStudentsView.vue"
        ),
    },
    {
      path: "students/:id",
      name: "teacher-student-id",
      component: () =>
        import(
          /* webpackChunkName: "My Students View" */ "@/modules/teachers/views/Students/StudentView.vue"
        ),
    },
    {
      path: "students/feedback",
      name: "teacher-students-feedback",
      component: () =>
        import(
          /* webpackChunkName: "Students Feedback View" */ "@/modules/teachers/views/Students/StudentsFeedbackView.vue"
        ),
    },
    {
      path: "calendar",
      name: "teacher-calendar",
      component: () =>
        import(
          /* webpackChunkName: "Calendar View" */ "@/modules/teachers/views/Calendar/CalendarView.vue"
        ),
    },
    {
      path: ":pathMatch(.*)*",
      redirect: {
        name: "teacher-dashboard",
      },
    },
  ],
};
