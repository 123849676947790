export const studentInformation = (state) => {
  return state.student.information;
};
export const studentCreditCard = (state) => {
  return state.student.card;
};

export const studentLastTeacherModal = (state) => {
  return state.modals.student.lastTeacher;
};

export const studentClassesInCalendar = (state) => {
  return state.student.classes.isCalendar;
};
export const profilePersonalDetails = (state) => {
  return state.profile.details;
};

export const billingPayment = (state) => {
  return state.billing.payment;
};

export const billingHistory = (state) => {
  return state.billing.history;
};

export const billingDeleteCardModal = (state) => {
  return state.modals.billing.deleteCard.isOpen;
};

export const billingSuscriptionDelete = (state) => {
  return state.modals.billing.deleteSuscription.isOpen;
};

export const teacherIsShowInfo = (state) => {
  return state.modals.teacher.info.isOpen;
};

export const teachersAll = (state) => {
  return state.teachers.all;
};

export const teachersAllAvailable = (state) => {
  return state.teachers.available;
};

export const teachersFavorites = (state) => {
  return state.teachers.favorites;
};

export const helpIsLoadingQuestions = (state) => {
  return state.help.isLoading;
};
export const helpCategoryQuestions = (state) => {
  return state.help.categoryQuestions;
};

export const helpSearchResults = (state) => {
  return state.help.searchResults;
};

export const helpIsHelpSearching = (state) => {
  return state.help.isSearching;
};

export const helpCurrentQuestionIds = (state) => {
  return state.help.searchResults.currentQuestionIds;
};

export const rewardsIsLoading = (state) => {
  return state.rewards.isLoading;
};

export const rewardsTypesDiscounts = (state) => {
  return state.rewards.typesDiscounts;
};

export const rewardsDiscountCode = (state) => {
  return state.rewards.discountCode;
};

export const toggleTerms = (state) => {
  return state.modals.rewards.terms.isOpen;
};

export const logout_student = (state) => {
  return state.student.logout_student;
};

export const getTeachers = (state) => {
  return state.teachers;
};

export const dashboardStart = (state) => {
  return state.dashboard;
};

export const classesTakenThisWeek = (state) => {
  return state.student.classes.takenThisWeek;
};

export const classesTakenThisDay = (state) => {
  return state.student.classes.takenThisDay;
};

export const lastClass = (state) => {
  return state.teachers.lastClass;
};

export const feedback = (state) => {
  return state.feedback;
};

export const teacherModal = (state) => {
  return state.teacherModal;
};

export const feedbacks = (state) => {
  return state.feedbacks;
};

export const classesThisWeekPending = (state) => {
  return state.student.classes.classesThisWeekPending;
};

export const questions = (state) => {
  return state.questions;
};

export const scheduledClass = (state) => {
  return state.scheduledClass;
};

export const availableTeachers = (state) => {
  return state.availableTeachers;
};

export const teachersClass = (state) => {
  return state.teachersClass;
};

export const infoClassModal = (state) => {
  return state.infoClassModal;
};

export const availablescheduleds = (state) => {
  return state.availablescheduleds;
};

export const teacherSelect = (state) => {
  return state.teacherSelect;
};

export const formCard = (state) => {
  return state.formCard;
};

export const startTour = (state) => {
  return state.startTour;
}

export const pendingFeedbacks = (state) => {
  return state.pendingFeedbacks;
}

export const confirmTour = (state) => {
  return state.modals.student.confirmTour;
}

export const cancelClass  = (state) => {
  return state.modals.student.cancelClass;
}

export const deleteFavorite = (state) => {
  return state.modals.teacher.deleteFavorite;
}

export const stadisticLessons = (state) => {
  return state.student.statistics_lessons;
}