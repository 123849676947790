import { apiServiceWithToken } from "@/core/services/apiService";

const module = "admin/panel";

const getDashboardStart = async () => {
  const config = {
    endpoint: `${module}/dashboard/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getStudentsStart = async (page) => {
  const config = {
    endpoint: `${module}/students/start?${page}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};
const getStudentsStartByReport = async (data, page) => {
  const config = {
    endpoint: `${module}/students/reports?${page}`,
    method: "POST",
    data,
  };
  return await apiServiceWithToken(config);
};

const getStudentById = async (id) => {
  const config = {
    endpoint: `${module}/students/${id}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getStudentPaymentById = async (id) => {
  const config = {
    endpoint: `${module}/students/${id}/payment-info`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const postCreateLead = async (data) => {
  const config = {
    endpoint: `${module}/students`,
    method: "POST",
    data,
  };
  return await apiServiceWithToken(config);
};

const getTeachersStart = async () => {
  const config = {
    endpoint: `${module}/teachers/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getTeachersStartByReport = async (data) => {
  const config = {
    endpoint: `${module}/teachers/reports`,
    method: "POST",
    data,
  };
  return await apiServiceWithToken(config);
};

const getTeacherById = (id) => {
  const config = {
    endpoint: `${module}/teachers/${id}`,
    method: "GET",
  };
  return apiServiceWithToken(config);
};

const postCreateTeacher = async (data) => {
  const config = {
    endpoint: `${module}/teachers`,
    method: "POST",
    data,
    formData: true,
  };
  return await apiServiceWithToken(config);
};

const postUpdateTeacher = async ({ data, id }) => {
  const config = {
    endpoint: `${module}/teachers/${id}`,
    method: "POST",
    data,
    formData: true,
  };
  return await apiServiceWithToken(config);
};
const updateJobTeacher = async ({ data, id }) => {
  const config = {
    endpoint: `${module}/teachers/${id}/work-scheduled`,
    method: "POST",
    data,
    formData: true,
  };
  return await apiServiceWithToken(config);
};

const putUpdateRoleTeacher = async ({ id, data }) => {
  const config = {
    endpoint: `${module}/teachers/${id}/role`,
    method: "PUT",
    data,
  };
  return await apiServiceWithToken(config);
};

const getAdminsStart = async () => {
  const config = {
    endpoint: `${module}/admins/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getAdminById = async (id) => {
  const config = {
    endpoint: `${module}/admins/${id}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const postCreateAdmin = async (data) => {
  const config = {
    endpoint: `${module}/admins`,
    method: "POST",
    data,
    formData: true,
  };
  return apiServiceWithToken(config);
};

const postUpdateAdmin = async ({ data, id }) => {
  const config = {
    endpoint: `${module}/admins/${id}`,
    method: "POST",
    data,
    formData: true,
  };
  return apiServiceWithToken(config);
};

const getLibraryStart = async () => {
  const config = {
    endpoint: `${module}/libraries`,
    method: "GET",
  };
  return apiServiceWithToken(config);
};

const getLibraryById = async (id) => {
  const config = {
    endpoint: `${module}/libraries/${id}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const postCreateLibrary = async (data) => {
  const config = {
    endpoint: `${module}/libraries`,
    method: "POST",
    data,
    formData: true,
  };
  return await apiServiceWithToken(config);
};

const postUpdateLibrary = async ({ data, id }) => {
  const config = {
    endpoint: `${module}/libraries/${id}`,
    method: "POST",
    data,
    formData: true,
  };
  return await apiServiceWithToken(config);
};

const getCalendarStart = async () => {
  const config = {
    endpoint: `${module}/calendar/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getscheduledClasses = async ({ lesson_start, lesson_end }) => {
  const config = {
    endpoint: `${module}/calendar/scheduled-class?lesson_start=${lesson_start}&lesson_end=${lesson_end}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getFinancialStart = async () => {
  const config = {
    endpoint: `${module}/financials/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getMarketingRegistersStart = async () => {
  const config = {
    endpoint: `${module}/registers/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getMarketingRewardsApprovalStart = async () => {
  const config = {
    endpoint: `${module}/rewards/start/for-approval`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getMarketingRewardsApprovalById = async ({ id }) => {
  const config = {
    endpoint: `${module}/rewards/start/for-approval/${id}`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const getMarketingRewardsApprovedStart = async () => {
  const config = {
    endpoint: `${module}/rewards/start/approved`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const validateZoom = async (email) => {
  const config = {
    endpoint: `${module}/teachers/zoom/validate`,
    method: "POST",
    data: { email },
  };
  return await apiServiceWithToken(config);
};

const getLibrary = async () => {
  const config = {
    endpoint: `${module}/libraries`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const calendar = async () => {
  const config = {
    endpoint: `${module}/calendar/start`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const sendResetEmails = async (payload) => {
  const config = {
    endpoint: `${module}/students/resetPasswords`,
    method: "POST",
    data: { emails: payload },
  };
  return await apiServiceWithToken(config);
};

const saveTemplate = async (payload) => {
  const config = {
    endpoint: `${module}/templates`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

const sendEmails = async (payload) => {
  const config = {
    endpoint: `${module}/send-email`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

const changeRole = async (payload, id) => {
  const config = {
    endpoint: `${module}/teachers/${id}/role`,
    method: "PUT",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const approveReward = async (payload, id) => {
  const config = {
    endpoint: `${module}/rewards/${id}/approved`,
    method: "PATCH",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const upgradeStudent = async (payload, id) => {
  const config = {
    endpoint: `${module}/students/changeLevel/${id}`,
    method: "PUT",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

export const createContentLibrary = async (payload) => {
  const config = {
    endpoint: `${module}/libraries`,
    method: "POST",
    data: payload,
    formData: true,
  };
  return await apiServiceWithToken(config);
};

export const updateContentLibrary = async (payload, id) => {
  const config = {
    endpoint: `${module}/libraries/contents/${id}`,
    method: "POST",
    data: { ...payload, _method: "PUT" },
    formData: true,
  };
  return await apiServiceWithToken(config);
};
export const deleteContentLibrary = async (payload, id) => {
  const config = {
    endpoint: `${module}/libraries/contents/${id}`,
    method: "DELETE",
    data: { ...payload },
    formData: true,
  };
  return await apiServiceWithToken(config);
};

export const getPendingFeedbacks = async () => {
  const config = {
    endpoint: `${module}/teachers/pending-feedback`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

export const getTeachersClassesDone = async () => {
  const config = {
    endpoint: `${module}/teachers/teachers-classes`,
    method: "GET",
  };
  return await apiServiceWithToken(config);
};

const updateStudentLead = async (payload, id) => {
  const config = {
    endpoint: `${module}/students/${id}`,
    method: "PUT",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

const filterReportFinancial = async (payload) => {
  const config = {
    endpoint: `${module}/financials/start/filter`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

const getScheduleWork = async (payload, id) => {
  const config = {
    endpoint: `${module}/teachers/${id}/work-schedule`,
    method: "POST",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

const saveSchedule = async (payload, id) => {
  const config = {
    endpoint: `${module}/teachers/${id}/work-schedule`,
    method: "PUT",
    data: payload,
  };
  return await apiServiceWithToken(config);
};

const blockTeacher = async (id) => {
  const config = {
    endpoint: `${module}/teachers/${id}`,
    method: "DELETE",
    data: {},
  };
  return await apiServiceWithToken(config);
};

const changeTeacher = (isLesson, idNewTeacher) => {
  const config = {
    endpoint: `${module}/students/lessons/${isLesson}/changeTeacher`,
    method: "PUT",
    data: { teacher_id: idNewTeacher },
  };
  return apiServiceWithToken(config);
};

export const adminsServices = {
  changeTeacher,
  blockTeacher,
  saveSchedule,
  getScheduleWork,
  getTeachersClassesDone,
  filterReportFinancial,
  createContentLibrary,
  getPendingFeedbacks,
  updateContentLibrary,
  upgradeStudent,
  approveReward,
  changeRole,
  sendEmails,
  saveTemplate,
  sendResetEmails,
  calendar,
  getLibrary,
  validateZoom,
  getDashboardStart,
  getStudentsStart,
  getStudentsStartByReport,
  getStudentById,
  getStudentPaymentById,
  postCreateLead,
  getTeachersStart,
  getTeachersStartByReport,
  getTeacherById,
  postCreateTeacher,
  postUpdateTeacher,
  updateJobTeacher,
  putUpdateRoleTeacher,
  getAdminsStart,
  getAdminById,
  postCreateAdmin,
  postUpdateAdmin,
  getLibraryStart,
  getLibraryById,
  postCreateLibrary,
  postUpdateLibrary,
  getCalendarStart,
  getscheduledClasses,
  getFinancialStart,
  getMarketingRegistersStart,
  getMarketingRewardsApprovalStart,
  getMarketingRewardsApprovedStart,
  getMarketingRewardsApprovalById,
  updateStudentLead,
  deleteContentLibrary,
};
