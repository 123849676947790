export default () => ({
  currentTime: new Date(),
  userAuthentication: {
    role: null,
    token: null,
    isLogged: false,
    status: "",
    last_status: "",
    token_firebase: "",
    timezone: "America/Detroit",
  },
  currentSidebar: [],
  modals: {
    logoutModalIsOpen: false,
    notificationClass: {
      isOpen: false,
      data: {},
    },
    notificationList: {
      isOpen: false,
      data: {},
    },
    userInfo: {
      isOpen: false,
      data: {},
    },
    classBooked: {
      isOpen: false,
      loadingSave: false,
    },
    confirmModal: {
      confirmModalOpen: false,
      confirmModalTitle: '',
      confirmModalQuestion: '',
      acceptRequest: false
    }
  },
  statusNotification: {
    status: "",
    message: "",
    isOpen: false,
  },
  library: {
    isLoading: true,
    levels: [],
    currentLevel: {},
    indexLevel: 0,
    currentLevelContent: {
      title: "",
      content: "",
    },
    currentLevelNext: {},
  },
  calendar: {
    events: [],
    userEmail: "",
    isLoggedIn: false,
  },
  listings: {
    countrycodes: {
      isLoading: true,
      data: [],
    },
    timesZones: {
      isLoading: true,
      data: [],
    },
    genders: [
      { value: "Female" },
      { value: "Male" },
      { value: "Prefer not to say" },
    ],
    spanishKnowledge: {
      data: [],
      isLoading: true,
    },
    learningPath: {
      data: [],
      isLoading: true,
    },
    levels: {
      data: [],
      isLoading: true,
    },
    roles: [
      { value: "Admin", id: 1 },
      { value: "Coordinator", id: 2 },
    ],
    days: [
      { value: "Monday", id: 1 },
      { value: "Tuesday", id: 2 },
      { value: "Wednesday", id: 3 },
      { value: "Thursday", id: 4 },
      { value: "Friday", id: 5 },
      { value: "Saturday", id: 6 },
      { value: "Sunday", id: 7 },
    ],
  },
  feedbackOrNoteConfirm :{
    data:{},
    isOpenConfirm:false,
    isLoading:false,
  },
  menuHamburger:{
    isOpen:false,
  }
});
