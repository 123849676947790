export default () => ({
  modals: {
    teacher: {
      info: {
        isOpen: false,
      },
      deleteFavorite:{
        isLoading: false,
        isOpen: false,
      }
    },
    student: {
      lastTeacher: {
        isOpen: false,
        mood: "new",
      },
      confirmTour:{
        isOpen: false,
      },
      cancelClass:{
        isOpen: false,
        id: null,
        classe:null,
        isLoading:false,
      }
    },
    billing: {
      deleteCard: {
        isOpen: false,
      },
      deleteSuscription: {
        isOpen: false,
      },
    },
    rewards: {
      terms: {
        isOpen: false,
      },
    },
  },
  pendingFeedbacks:{
    data:[],
    isLoading:true,
  },
  billing: {
    payment: {
      /* moods: edit,  delete */
      mood: "",
      updating: false,
      hasCreditCard: true,
      moodEdit:false,
      isLoading: true,
    },
    history: {
      data: [],
      isLoading: true,
      withCard: true,
    },
  },
  student: {
    information: {
      is_new_student: false,
      isLoading: false,
      isLoadingAccount:false
    },
    logout_student: false,
    card: {},
    statistics_lessons: {},
    classes: {
      takenThisDay:{
        isLoading:true,
        data:[]
      },
      takenThisWeek: {
        isLoading: true,
        data: [],
        total: 0,
      },
      isCalendar: {
        isLoading: true,
        data: [],
      },
      classesThisWeekPending: {
        isLoading: true,
        data: [],
        nextClass: {},
      },
    },
  },
  dashboard: {
    isLoading: true,
    data: {},
    totalClasses: 0,
    totalClassesMonth: 0,
    totalClassesWeek: 0,
  },
  teachers: {
    all: {
      isLoading: true,
      data: [],
      filter: false,
      search: "",
    },
    available:{
      isLoading:true,
      data:[]
    },
    favorites: {
      isLoading: true,
      data: [],
    },
    lastClass: {
      isLoading: true,
      data: {},
    },
  },
  profile: {
    details: {
      status: {
        /* moods: edit - update */
        mood: "update",
        isLoading: false,
      },
    },
    payment: {},
  },
  help: {
    categoryQuestions: [],
    isLoading: true,
    searchResults: {
      currentCategoriesIds: [],
      currentQuestionIds: [],
      questions: [],
    },
    isSearching: false,
  },
  rewards: {
    isLoading: true,
    typesDiscounts: [],
    discountCode: "",
  },
  feedback: {
    data: {},
  },
  teacherModal: {
    data: {},
    type: "normal",
    found: false,
  },
  feedbacks: {
    isLoading: true,
    data: [],
  },
  questions: {
    isLoading: true,
    data: [],
  },
  scheduledClass: {
    data: [],
  },
  teachersClass: {
    data: [],
  },
  teacherSelect: {
    data: {},
  },
  availableTeachers: {
    isLoading: true,
    data: [],
  },
  infoClassModal: {
    data: [],
  },
  availablescheduleds: {
    isLoading: true,
    data: [],
  },
  formCard: {
    data: {},
  },
  startTour: {
    isOpen: false,
  },
});
