import moment from 'moment'

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const day = moment().day();

const formatDate = (date, type = "default") => {
  if (date !== undefined && date !== null && date !== "-") {
    if (type === "default")
      return`/${date.substr(5, 2)}/${date.substr(8, 2)}/${date.substr(0, 4)}`; // mm/dd/yyyy
    else if (type === "month")
      return `${
        monthNames[parseInt(date.substr(5, 2))-1] // month dd, YYYY
      } ${date.substr(8, 2)}, ${date.substr(0, 4)}`;
    else if (type === "large")
      return `${
        monthNames[parseInt(date.substr(5, 2))-1]
      } ${date.substr(8, 2)} ${date.substr(0, 4)} at ${date.substr(11, 5)}`;// month dd yyyy at hh:mm
    else if (type === "complete")
      return `${
        monthNames[parseInt(date.substr(5, 2))-1]
      }, ${moment(date).format("dddd")} ${date.substr(8, 2)} ${date.substr(0, 4)} at ${date.substr(11, 5)}`;// day month dd yyyy at hh:mm
    else if (type === "modalClassTeacher") {
      let formatDateStart = parseInt(date.substr(11, 2));
      let formatEndStart = parseInt(date.substr(11, 2));
      formatDateStart >= 12
        ? (formatDateStart = "PM")
        : (formatDateStart = "AM");
      formatEndStart >= 12 ? (formatEndStart = "PM") : (formatEndStart = "AM");
      return `${date.substr(11, 5)} ${formatDateStart} TO ${date.substr(
        30,
        5
      )} ${formatEndStart}`; // hh:mm AM TO hh:mm PM
    } else if (type === "scheduledClass") {
      let newDate = moment(date).day();

      return `${day === newDate ? "Today, " : ""} ${monthNames[parseInt(date.substr(5, 2))-1]} ${
        dayNames[newDate]
      }  ${date.substr(
        8,
        2
      )} - ${date.substr(11, 8)}`; // day month dd yyyy at hh:mm
    } else if (type === "modalscheduledClass") {
      return `${monthNames[parseInt(date.substr(5, 2))]} ${date.substr(
        8,
        2
      )} at ${date.substr(11, 5)}`; // month dd at hh:mm
    } else if (type === "paymentInfo") {
      return `${monthNames[parseInt(date.substr(5, 2))-1]} ${date.substr(
        8,
        2
      )},  ${date.substr(0, 4)}`; // month dd yyyy
    } else if (type === "lastPays") {
      return `${date.substr(5, 2)}/${date.substr(8, 2)}/${date.substr(0, 4)}`; // month/dd/yyyy
    }else if(type ==="calendarAdmin"){
      return `${monthNames[parseInt(date.substr(5, 2))]}, ${dayNames[day]} ${date.substr(8,2)}`; // month, day day 
    } else if(type === "notification") {
      return `${monthNames[parseInt(date.substr(5, 2))-1]} ${date.substr(
        8,
        2
      )},  ${date.substr(0, 4)}`; // month dd, yyyy
    }else if (type === "scheduled"){
      const new_date = moment(date).format('dddd Do') + " <br> at " +  moment(date).format('LT')
      return new_date
    }else if( type === "american"){
      return `${date.substr(5, 2)}/${date.substr(8, 2)}/${date.substr(0, 4)}`; // month/dd/yyyy
    }else if(type === "changeTeacher"){
      let newDate = moment(date).day();
      return `${dayNames[newDate]}, ${monthNames[parseInt(date.substr(5, 2))-1]} ${date.substr(8, 2)}`; // day month dd yyyy

    }
  }
  return "-";
};

export default formatDate;

// <!-- Thursday , 09 December 2021 at 05:30pm -->
