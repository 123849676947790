export default {
  name: "public",
  component: () =>
    import(
      /* webpackChunkName: "Public Layout" */ "@/shared/layouts/PublicLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "login",
      component: () =>
        import(
          /* webpackChunkName: "Login View" */ "@/modules/public/views/LoginView.vue"
        ),
    },
    {
      path: "forgot-password",
      name: "forgot-password",
      component: () =>
        import(
          /* webpackChunkName: "Forgot Password View" */ "@/modules/public/views/ForgotPasswordView.vue"
        ),
    },
    {
      template: "@/modules/public/views/ForgotPasswordView.vue",
      path: "create-password/:token",
      name: "create-password",
      component: () =>
        import(
          /* webpackChunkName: "Create Password View" */ "@/modules/public/views/CreatePasswordView.vue"
        ),
      // beforeEnter: [tokenValidate],
    },
    {
      path: ":pathMatch(.*)*",
      redirect: {
        name: "login",
      },
    },
  ],
};
