const stylesNotificationList = () => {
  const notificationList = document.querySelector(".notification-list-modal");
  const screenWidth = window.innerWidth;
  if (notificationList) {
    const modalNotificationList = notificationList.parentElement;
    modalNotificationList.style.left =  screenWidth > 768  ? "-13rem" : '-2rem';
  }
};

export default stylesNotificationList;
