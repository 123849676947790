<template>
  <router-view />
  <notification-status-component />
</template>

<script>
import NotificationStatusComponent from "@/shared/components/Notifications/NotificationStatusComponent";

export default {
  name: "App",
  components: {
    NotificationStatusComponent,
  },
};
</script>
