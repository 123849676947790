const uiMainItemsData = [
  {
    label: "Dashboard",
    icon: "home",
    name: "teacher-dashboard",
  },
  {
    label: "My library",
    icon: "library_books",
    name: "teacher-library",
  },
  {
    label: "My students",
    icon: "school",
    name: "teacher-students",
  },
  {
    label: "My calendar",
    icon: "calendar_today",
    name: "teacher-calendar",
  },
];
export default uiMainItemsData;
