import moment from "moment";

const getFollowedClasses = (classes) => {
  let followedDates = [];
  let isStart = false;
  let finalRange = "";
  let arraysIdLessons = [];
  classes.forEach((classe, index) => {
    if (index < classes.length - 1) {
      const nextDate = classes[index + 1].lesson_start;
      const diff = Math.abs(
        moment(nextDate).diff(moment(classe.lesson_start), "minutes")
      );
      if (diff > 30) {
        isStart = false;
        if (finalRange !== "") {
          classe.lesson_end = finalRange;
          finalRange = "";
          followedDates.push({ ...classe, arraysIdLessons });
          arraysIdLessons = [];
        } else {
          followedDates.push(classe);
        }
        if (index === classes.length - 2) {
          followedDates.push({ ...classes[index + 1], arraysIdLessons });
        }
    } else {
        let same_person = false;
        if (classe.hasOwnProperty("student_id")){
          same_person = classes[index + 1].student_id === classe.student_id;
        }
        else if (classe.hasOwnProperty("teacher_id")){
          same_person = classes[index + 1].teacher_id === classe.teacher_id;
        }

        if (same_person) {
          if (!isStart) {
            finalRange = classes[index].lesson_end;
            isStart = true;
          }
          arraysIdLessons.push(
            classe.hasOwnProperty("lesson_id") ? classe.lesson_id : classe.id
          );
          arraysIdLessons.push(
            classe.hasOwnProperty("lesson_id")
              ? classes[index + 1].lesson_id
              : classes[index + 1].id
          );

          finalRange = classe.lesson_end;
          if (index === classes.length - 2) {
            classe.lesson_end = finalRange;
            classe.lesson_start = classes[index + 1].lesson_start;
            followedDates.push({ ...classe, arraysIdLessons });
          }
        } else {
          followedDates.push(classe);
        }
      }
    } else if (index === 0) {
      followedDates.push(classe);
    }
  });
  // console.log(followedDates)
  followedDates.forEach((classe) => {
    let hash = {};
    classe.feedback = classe.feedback.filter((event) =>
      hash[event.title] ? false : (hash[event.title] = true)
    );
  })
 
  if (followedDates.length > 50) return followedDates.slice(0, 50);
  return followedDates;
};

const getInitialDates = (classe) => {
  const initialDates = [];
  const end = classe.lesson_end;
  let diff = moment(end).diff(moment(classe.lesson_start), "minutes");
  if (diff > 30) {
    let minutesMore = 0;
    while (diff >= 30) {
      const newDate = {
        ...classe,
        lesson_start: moment(classe.lesson_start)
          .add(minutesMore, "minutes")
          .format("YYYY-MM-DD HH:mm"),
        lesson_end: moment(classe.lesson_start)
          .add(minutesMore + 30, "minutes")
          .format("YYYY-MM-DD HH:mm"),
      };
      initialDates.push(newDate);
      diff = diff - 30;
      minutesMore = minutesMore + 30;
    }
  } else {
    initialDates.push(classe);
  }
  return initialDates;
};

const hasMoreTwoClasses = (classe) => {
  return (
    Math.abs(
      moment(classe.lesson_end).diff(moment(classe.lesson_start), "minutes")
    ) > 30
  );
};

export { getFollowedClasses, getInitialDates, hasMoreTwoClasses };
