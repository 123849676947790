import moment from 'moment-timezone'

export const logoutModalIsOpen = (state) => {
  return state.modals.logoutModalIsOpen;
};

export const currentTime = (state) => {
  return state.currentTime;
};

export const confirmModalOpen = (state) => {
  return state.modals.confirmModal.confirmModalOpen;
};
export const confirmModalTitle = (state) => {
  return state.modals.confirmModal.confirmModalTitle;
};
export const confirmModalQuestion = (state) => {
  return state.modals.confirmModal.confirmModalQuestion;
};
export const confirmModalAccept = (state) => {
  return state.modals.confirmModal.acceptRequest;
};

export const notificationClass = (state) => {
  return state.modals.notificationClass;
};

export const notificationStatusInfo = (state) => {
  return state.statusNotification;
};

export const getUserAuthentication = (state) => {
  return state.userAuthentication;
};

export const getSidebarItems = (state) => {
  return state.currentSidebar;
};

export const getUserInfoModal = (state) => {
  return state.modals.userInfo;
};

export const getClassBookedModal = (state) => {
  return state.modals.classBooked;
};

export const getNotificationList = (state) => {
  return state.modals.notificationList;
};

export const getCurrentLibrary = (state) => {
  return state.library;
};

export const getCalendarEvents = (state) => {
  return state.calendar.events;
};

export const getListings = (state) => {
  return state.listings;
};

export const feedbackOrNoteConfirm = (state) => {
  return state.feedbackOrNoteConfirm;
}

export const menuHamburger  = (state) => {
  return state.menuHamburger;
}

export const getTimezone = (state) => {
  return state.userAuthentication.timezone
}

export const currentTimeTimezone = (state) => {
  const date = moment.tz(state?.currentTime || new Date(), "America/Detroit");

  date.tz(state?.userAuthentication.timezone || "America/Detroit").format()

  return date.format('YYYY/MM/DD HH:mm')
}

export const getServerTime = (state) => {
  const date = moment(state?.currentTime || new Date());
  return date.format('YYYY/MM/DD HH:mm')
}