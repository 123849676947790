import { teacherServices } from "../services/teacherServices";
import formatDate from '@/shared/utils/changeTexts/formatDate';
import { getFollowedClasses }  from "../../../shared/helpers/getFollowedClasses";
import moment from "moment";

const root = { root: true };

export const dashboardStart = async ({ commit, rootGetters }) => {
  try {
    const teacherPromise = await teacherServices.getDashboardStart();
    if (teacherPromise) {
      const { data } = await teacherPromise.json();
      let { teacher_data } = data;
      if (data.teacher_lessons_of_day.length > 0) {
        var currentDate = moment(new Date(rootGetters['shared/currentTimeTimezone']), "YYYY-MM-DD HH:mm:ss");
        var date = moment(
          data.teacher_lessons_of_day[0].lesson_start,
          "YYYY-MM-DD HH:mm:ss"
        );

        if (date > currentDate) {
          commit(
            "shared/uiToggleNotificationClass",
            {
              data: data.teacher_lessons_of_day[0],
              isOpen: true,
            },
            root
          );
        } else {
          if (
            data.teacher_lessons_of_day[1] !== undefined ||
            data.teacher_lessons_of_day[1]
          ) {
            commit(
              "shared/uiToggleNotificationClass",
              {
                data: data.teacher_lessons_of_day[1],
                isOpen: true,
              },
              root
            );
          }
        }
      }
      commit("teachers/teacherInfo", { ...teacher_data }, root);
      commit("admins/adminInfo", { isLoading: false }, root);
      commit(
        "teachers/dashboardStart",
        { data: data, isLoading: false, totalClasses: data.total_lessons_done },
        root
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const libraryLevels = async ({ commit }) => {
  try {
    const teacherPromise = await teacherServices.getLevels();
    if (teacherPromise) {
      const res = await teacherPromise.json();
      const levels = res.general_content;
      const currentLevel = levels[0];
      commit(
        "shared/updateLibrary",
        {
          levels,
          currentLevel,
          isLoading: false,
          currentLevelNext: levels[1],
        },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const getStudents = async ({ commit }) => {
  try {
    const teachersPromise = await teacherServices.getStudents();
    if (teachersPromise) {
      const res = await teachersPromise.json();
      let { data } = res;
      data.forEach(student => {
        student.status = "offline";
        student.age =student.age.toString()
        
      })
      commit("teachers/students", { data, isLoading: false }, root);
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const getStudent = async ({ commit, dispatch }, { id }) => {
  try {
    const teachersPromise = await teacherServices.getStudent(id);
    if (teachersPromise) {
      const res = await teachersPromise.json();
      const notification = { isOpen: true };

      if(res.status === "error"){
        notification.status = "error";
        notification.message = res.message;
        commit("shared/uiNotificationStatus", notification, root);

      }else{
        let { data } = res;
        data.comments = getFollowedClasses(data.comments.filter(item => item !== null));
        data.comments.forEach((comment) => {
          comment.lesson_start_other = comment.lesson_start;
          comment.lesson_start = formatDate(comment.lesson_start,'american');
        })
        commit("teachers/student", { data: data, isLoading: false }, root);
      }
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const upgradeLevel = async ({ commit, dispatch }, { payload, id }) => {
  try {
    const teachersPromise = await teacherServices.upgradeLevel(payload, id);
    if (teachersPromise) {
      const res = await teachersPromise.json();
      const notification = { isOpen: true };
      if (res.status === "error") {
        notification.status = "error";
        notification.message = res.message;
      } else {
        notification.status = "success";
        notification.message = "Level upgraded successfully.";
      }
      commit("shared/uiNotificationStatus", notification, root);

      return { res };
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const classesTakenThisWeek = async ({ commit }) => {
  try {
    const classesTakenThisWeek = await teacherServices.getClassesWeek();
    if (classesTakenThisWeek) {
      const resJSON = await classesTakenThisWeek.json();

      commit(
        "teachers/teacherClassesTaughtThisWeek",
        {
          data: resJSON,
          isLoading: false,
        },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const classesTakenThisDay = async ({ commit }) => {
  try {
    const classesTakenThisDayPromise = await teacherServices.getClassesDay();
    if (classesTakenThisDayPromise) {
      const resJSON = await classesTakenThisDayPromise.json();
      commit(
        "teachers/teacherClassesForDay",
        {
          isLoading: false,
          data: resJSON,
        },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const allClasses = async ({ commit }) => {
  try {
    const allClassesPromise = await teacherServices.getAllClasses();
    if (allClassesPromise) {
      const resJSON = await allClassesPromise.json();
      // const newResJson = getFollowedClasses(resJSON);
      const newResJson = resJSON;
      commit(
        "teachers/allClasses",
        {
          data: newResJson,
          isLoading: false,
        },
        root
      );
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const resetPassword = async ({ commit, dispatch }, { payload }) => {
  try {
    const resetPasswordPromise = await teacherServices.resetPassword(payload);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: resetPasswordPromise, isShowSuccess: true },
      root
    );
    // console.log(success);
    return { success, json };
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const questionsTeacher = async ({ commit, dispatch }) => {
  try {
    const questionsTeacherPromise = await teacherServices.questionsTeacher();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: questionsTeacherPromise, isShowSuccess: false },
      root
    );
    if (success) {
      commit("teachers/questions", { data: json, isLoading: false }, root);
    }
    return { success, json };
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const sendFeedback = async ({ commit, dispatch }, { payload }) => {
  try {
    const sendFeedbackPromise = await teacherServices.sendFeedback(payload);
    if (sendFeedbackPromise) {
      const sendFeedbackPromiseJSON = await sendFeedbackPromise.json();
      const notification = { isOpen: true };
      let res = true;
      if (sendFeedbackPromiseJSON.status === "error") {
        notification.message = "Please try again and verify that you have filled all the fields.";
        notification.status = "error";
        res = false;
      } else {
        notification.message = `Your feedback was submitted successfully.`;
        notification.status = "success";
        commit("teachers/studentToggleFeedbackModal", { isOpen: false }, root);
      }
      commit("shared/uiNotificationStatus", notification, root);
      return res;
    }
  } catch (ex) {
    //console.log(ex);
  }
};

export const coordinators = async ({ commit, dispatch }) => {
  try {
    const coordinatorsPromise = await teacherServices.coordinators();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: coordinatorsPromise, isShowSuccess: false },
      root
    );
    if (success) {
      commit(
        "teachers/coordinators",
        { data: json, isLoadingTotal: false },
        root
      );
    }
    return { success, json };
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const finishClasses = async ({ commit, dispatch }) => {
  try {
    const finishClassesPromise = await teacherServices.finishClasses();
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response: finishClassesPromise, isShowSuccess: false },
      root
    );
    if (success) {
      commit(
        "teachers/finishClasses",
        { data: json, isLoading: false },
        root
      );
    }
    return { success, json };
  } catch (error) {
    console.error(error);
  } finally {
  }
};
