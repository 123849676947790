export default () => ({
  modals: {
    student: {
      feedback: {
        isOpen: false,
        data: {},
        mood: "new",
      },
      updateLevel: {
        isOpen: false,
      },
      resetPassword: {
        isOpen: false,
      },
      teacherNote : {
        isOpen: false,
        data: {},
      }
    },
  },
  dashboard:{
    data:[],
    isLoading:true,
    totalClasses:0
  },
  teacher: {
    info: null,
    classes: {
      takenThisWeek: {
        isLoading: true,
        data: [],
        total: 0,
      },
      inCalendar: {
        isLoading: true,
        data: [],
      },
      forDay: {
        isLoading: true,
        data: [],
      },
      allClasses:{
        isLoading:true,
        data:[]
      },
      finishedClasses:{
        isLoading:true,
        data:[]
      }
    },
  },
  teachers: {},
  students: {
    data: [],
    isLoading:true,
  },
  student: {
    data: {},
    isLoading:true
  },
  classModal :{
    isOpen:false,
    data:{}
  },
  questions:{
    data:[],
    isLoading:true
  },
  coordinators:{
    data:{},
    isLoadingTotal:true
  },
});
