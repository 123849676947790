import { computed, ref, watchEffect } from "vue";
import { useRouter , useRoute } from "vue-router";
import { useStore } from "vuex";
import moment_timezone from "moment-timezone";

import stylesNotificationList from "@/shared/utils/style/stylesNotificationList";

import typesRoles from "@/shared/types/typesRoles";
import teacherItemsData from "@/modules/teachers/data/UI/uiMainItemsData";
import studentstemsData from "@/modules/students/data/UI/uiMainItemsData";
import menuItems from "@/modules/admin/data/UI/menuItems";
import { ref as refDb, set, get, child } from "firebase/database";
import database from "../../firebase/config";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
const useSharedUI = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute()
  const isSendingRefer = ref(false);
  const isSendingClassesStudents = ref(false);
  const isSendingClassesTeachers = ref(false);
  const isSendingTotalInscriptions = ref(false);
  const isSendingPendingFeedbacks = ref(false);
  const isSendingFinishedClasses = ref(false);
  //modal de confirmación
  const confirmModalOpen = computed(
    () => store.getters["shared/confirmModalOpen"]
  );
  const confirmModalTitle = computed(
    () => store.getters["shared/confirmModalTitle"]
  );
  const confirmModalQuestion = computed(
    () => store.getters["shared/confirmModalQuestion"]
  );
  const acceptRequest = computed(
    () => store.getters["shared/confirmModalAccept"]
  );

  const confirmModal = (isOpen, title, question, callback) => {
    store.commit("shared/uiToggleConfirmModal", isOpen);
    store.commit("shared/uiConfirmModalTitle", title);
    store.commit("shared/uiConfigModalQuestion", question);
  };

  const configModalAccept = () => {
    store.commit("shared/uiConfigModalAccept");
  }

  /* ***** USER AUTHENTICATED ************ */
  const userAuthenticated = computed(
    () => store.getters["shared/getUserAuthentication"]
  );
  

  /* ***** LOGOUT ****************** */
  const toggleLogoutModal = (isOpen) => {
    store.commit("shared/uiToggleLogoutModal", isOpen);
  };

  const signOut = async () => {
    const { status, role, token_firebase } =
      store.getters["shared/getUserAuthentication"];

    store.commit("students/logout_student", true);
    let module =
      role === "student"
        ? "Spanish"
        : role === "teacher"
        ? "Teachers"
        : "Admins";

    store.commit("shared/uiToggleLogoutModal", false)
    store.commit("shared/feedbackOrNoteConfirm", { data : { } , isOpenConfirm :false , isLoading:false });
    localStorage.removeItem("bl-user");
    store.commit("shared/userAuthentication", {
      role: null,
      token: null,
      isLogged: false,
      is_new_student: false,
      has_random_password: false,
      completed_info: false,
    });
    if (
      status !== "offline" &&
      token_firebase &&
      token_firebase !== undefined
    ) {
      set(
        refDb(database, module + "/" + token_firebase + "/status"),
        "offline"
      );
    }
    router.push({ name: "login" });
  };
  const logoutModalIsOpen = computed(
    () => store.getters["shared/logoutModalIsOpen"]
  );

  /* ***** NOTIFICATION CLASS ************ */
  const toggleNotification = (isOpen) => {
    store.commit("shared/uiToggleNotificationClass", { isOpen });
  };

  const notificationClass = computed(
    () => store.getters["shared/notificationClass"]
  );

  /* **** NOTIFICATION STATUS **************+ */
  const notificationStatusInfo = computed(
    () => store.getters["shared/notificationStatusInfo"]
  );

  const closeNotificationStatusInfo = () => {
    store.commit("shared/uiNotificationStatus", {
      status: "",
      message: "",
      isOpen: false,
    });
  };

  const setNotifications = (token, path) => {
    const { data } = store.getters["shared/getNotificationList"];
    Object.entries(data).forEach(([key, value]) => {
      value.leido = 1;
      set(
        refDb(database, "notifications/" + path + "/" + token + "/" + key),
        value
      );
    });
  };

  const setNotificacionesLeidos = () => {
    const { role, token_firebase } =
      store.getters["shared/getUserAuthentication"];
    if (role === "student") {
      setNotifications(token_firebase, "Students");
    } else if (role === "teacher") {
      setNotifications(token_firebase, "Teachers");
    } else if (role === "admin") setNotifications(token_firebase, "Admins");
    else setNotifications(token_firebase, "Coordinators");
  };

  const setShowNotificaciones = (notificacion) => {
    const { role, token_firebase } =
      store.getters["shared/getUserAuthentication"];
    notificacion.show = 1;
    const path =
      role === "student"
        ? "Students"
        : role === "teacher"
        ? "Teachers"
        : role=== 'coordinator'? 'Coordinators' :"Admins";

        // console.log(notificacion)
    set(
      refDb(
        database,
        "notifications/" + path + "/" + token_firebase + "/" + notificacion.key
      ),
      notificacion
    );
    if (!notificacion.hasOwnProperty("withId")) {
      if(route.name === notificacion.route ){
          location.reload()
      }else{
        router.push({ name: notificacion.route });
      }
    } else {
      router.push({
        name: notificacion.route,
        params: { id: notificacion.withId },
      });
    }
  };

  const sendNotificationUpgradeLevel = (student_token, level) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "message",
      route: "student-profile",
      message: "Congrats! You have reached a new level! " /* + level */,
      title: "Level up",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    set(
      refDb(
        database,
        "notifications/Students/" + student_token + "/" + notification.key
      ),
      notification
    );
  };

  const sendNotificationNewTeacher = (teacherName) => {
    const dbRef = refDb(database);
    get(child(dbRef, `Spanish/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          Object.entries(snapshot.val()).forEach(([key, value]) => {
            const notification = {
              timezone: store.getters['shared/getTimezone'],
              key: uuidv4(),
              show: 0,
              leido: 0,
              type: "message",
              route: "student-my-teachers",
              message: "A new teacher just joined our team. Learn more here",
              // message: "New teacher " + teacherName + " added Meet him!",
              title: "New teacher",
              date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
            };
            set(
              refDb(
                database,
                "notifications/Students/" + key + "/" + notification.key
              ),
              notification
            );
          });
        } else {
          // console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendNotificationClassesStudent = async (total_classes) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "total",
      value: total_classes,
      route: "student-my-classes",
      next: total_classes + 100,
      // message: "You have achieved " + total_classes + " effective classes!",
      message:
        "Wow- you just completed your first " +
        total_classes +
        " classes! Congrats!",

      title: "Congratulations",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const { token_firebase } = store.getters["students/studentInformation"];
    if (token_firebase !== undefined) {
      let founded = {};
      const dbRef = refDb(database);

      if (!isSendingClassesStudents.value) {
        isSendingClassesStudents.value = true;
        await get(child(dbRef, `notifications/Students/` + token_firebase))
          .then(async (snapshot) => {
            let value_fb = snapshot.val();
            if (snapshot.exists()) {
              Object.entries(snapshot.val()).forEach(([key, value]) => {
                if (value.type === "total") {
                  founded = value;
                }
              });
              value_fb[notification.key] = notification;
              if (Object.entries(founded).length > 0) {
                if (total_classes === founded.next)
                  await set(
                    refDb(
                      database,
                      "notifications/Students/" + token_firebase + "/"
                    ),
                    value_fb
                  );
              } else {
                await set(
                  refDb(
                    database,
                    "notifications/Students/" + token_firebase + "/"
                  ),
                  value_fb
                );
              }
            } else {
              await set(
                refDb(
                  database,
                  "notifications/Students/" + token_firebase + "/"
                ),
                notification
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const sendNotificationFeedback = (feedback) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      feedback_id: feedback.id,
      show: 0,
      leido: 0,
      type: "feedback",
      route: "student-teachers-feedback",
      message: `Don’t forget to rate your learning experience ${feedback.teacher}`,
      title: "Pending feedback",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const { token_firebase } = store.getters["students/studentInformation"];
    if (token_firebase !== undefined) {
      let founded = {};
      const dbRef = refDb(database);
      if (!isSendingPendingFeedbacks.value)
        isSendingPendingFeedbacks.value = true;
      get(child(dbRef, `notifications/Students/` + token_firebase))
        .then(async (snapshot) => {
          let value_fb = snapshot.val();
          if (snapshot.exists()) {
            Object.entries(snapshot.val()).forEach(([key, value]) => {
              if (value.type === "feedback") {
                founded = value;
              }
            });
            value_fb[notification.key] = notification;
            if (Object.entries(founded).length > 0) {
              if (feedback.id !== founded.feedback_id)
                set(
                  refDb(
                    database,
                    "notifications/Students/" + token_firebase + "/"
                  ),
                  value_fb
                );
            } else {
              set(
                refDb(
                  database,
                  "notifications/Students/" + token_firebase + "/"
                ),
                value_fb
              );
            }
          } else {
            set(
              refDb(database, "notifications/Students/" + token_firebase + "/" + notification.key),
              notification
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const sendNotificationCancelClass = (classe, student) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "message",
      route: "teacher-calendar",
      // message: `Class ${classe.lesson_start_in_your_timezone} with   has been canceled`,
      message: `Your class with ${student} has been canceled; check your dashboard for more info`,
      title: "Class canceled",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    set(
      refDb(
        database,
        "notifications/Teachers/" +
          classe.token_firebase +
          "/" +
          notification.key
      ),
      notification
    );
  };

  const sendNotificationClassscheduled = (classe, token, student) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "message",
      route: "teacher-calendar",
      // message: `New class scheduledd by ${student} for ${classe.date} ${classe.time_start}`,
      message: `You have a new class with  ${student}. Check your dashboard for more info.`,
      title: "New class",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    set(
      refDb(
        database,
        "notifications/Teachers/" + token + "/" + notification.key
      ),
      notification
    );
  };

  const sendNotificationTeacherFavorite = (teacher) => {
    const coordinators = store.getters["teachers/coordinators"];
    const { timezone } = store.getters["shared/getUserAuthentication"];
    const studentInformation = store.getters["students/studentInformation"];
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "message",
      route: "admin-members-teachers",
      message: `${teacher.name}  ${teacher.last_name}  has been assigned as a favorite by ${studentInformation.name} ${studentInformation.last_name}`,

      title: "New favorite teacher",
      idSend: uuidv4(),
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    Object.entries(coordinators.data[0]).forEach(([key, value]) => {
      if (value.token_firebase !== undefined && value.token_firebase !== null)
        set(
          refDb(
            database,
            "notifications/Coordinators/" +
              value.token_firebase +
              "/" +
              notification.key
          ),
          notification
        );
    });
    Object.entries(coordinators.data[1]).forEach(([key, value]) => {
      if (value.token_firebase !== undefined && value.token_firebase !== null)
        set(
          refDb(
            database,
            "notifications/Admins/" +
              value.token_firebase +
              "/" +
              notification.key
          ),
          notification
        );
    });
  };

  const sendNotificationTotalInscripcions = async (totalInscriptions) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "register",
      route: "admin-analytics-marketing",
      // message: `There are ${totalInscriptions} inscriptions in the system`,
      message: `This month we got ${totalInscriptions} new subscriptions`,
      title: "Total registers",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const { token_firebase } = store.getters["admins/adminInfo"];
    if (token_firebase !== undefined) {
      const dbRef = refDb(database);
      if (!isSendingTotalInscriptions.value) {
        isSendingTotalInscriptions.value = true;
        await get(child(dbRef, `notifications/Admins/` + token_firebase))
          .then(async (snapshot) => {
            if (snapshot.exists()) {
              let value_fb = snapshot.val();
              let object_founded = {};
              Object.entries(snapshot.val()).forEach(([key, value]) => {
                if (
                  value.type === "register" &&
                  value.date.substr(0, 10) ===
                    moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD")
                ) {
                  object_founded = value;
                }
              });
              value_fb[notification.key] = notification;
              if (Object.entries(object_founded).length === 0) {
                const startOfMonth = moment(store.getters['shared/currentTimeTimezone'])
                  .startOf("month")
                  .format("YYYY-MM-DD hh:mm");
                const today = moment(store.getters['shared/currentTimeTimezone']).format("YYYY-MM-DD hh:mm");

                if (startOfMonth === today)
                  await set(
                    refDb(
                      database,
                      "notifications/Admins/" + token_firebase + "/"
                    ),
                    value_fb
                  );
              }
            } else {
              await set(
                refDb(database, "notifications/Admins/" + token_firebase + "/"),
                value_fb
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const sendNotificationFeedbackTeacher = () => {
    const { data } = store.getters["students/feedback"];
    if(data?.name){
      const coordinators = store.getters["teachers/coordinators"];
      const notification = {
        timezone: store.getters['shared/getTimezone'],
        key: uuidv4(),
        show: 0,
        leido: 0,
        type: "message",
        route: "admin-members-teachers",
        // message: `The teacher ${data.name}  ${data.last_name} has received feedback`,
        message: `${data.name}  ${data.last_name} has received feedback`,
        title: "New feedback for teacher",
        idSend: uuidv4(),
        date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
      };
      const dbRef = refDb(database);
      Object.entries(coordinators.data[0]).forEach(([key, value]) => {
        get(child(dbRef, `notifications/Coordinators/${value.token_firebase}/`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              let value_fb = snapshot.val();
              value_fb[notification.key] = notification;
              set(
                refDb(
                  database,
                  "notifications/Coordinators/" + value.token_firebase
                ),
                value_fb
              );
            } else {
              set(
                refDb(
                  database,
                  "notifications/Coordinators/" +
                    value.token_firebase +
                    "/" +
                    notification.key
                ),
                notification
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  };

  const sendNotificationReward = () => {
    const student = store.getters["students/studentInformation"];
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "message",
      route: "admin-analytics-marketing",
      // message: `You received a new reward request from ${student.name} ${student.last_name} - ${student.user_id}`,
      message: `You received a new Reward Request by ${student.name} ${student.last_name} with student’s code ${student.user_id}`,

      title: "New reward approval",
      idSend: uuidv4(),
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const dbRef = refDb(database);
    get(child(dbRef, `Admins/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          Object.entries(snapshot.val()).forEach(([key, value]) => {
            set(
              refDb(
                database,
                "notifications/Admins/" + key + "/" + notification.key
              ),
              notification
            );
          });
        } else {
          // console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* **** NOTIFICATION LIST ****************** */
  const toggleNotificationList = (isOpen) => {
    setNotificacionesLeidos()
    store.commit("shared/updateNotificationList", { isOpen });
    setTimeout(() => {
      stylesNotificationList();
    }, 100);
    if (isOpen) {
      // setNotificacionesLeidos();
    }
  };
  const getNotificationList = computed(
    () => store.getters["shared/getNotificationList"]
  );

  /* ***** BUILD MENU ITEMS ***************** */
  const currentSidebarItems = ref([]);
  const sidebarItems = computed(() => store.getters["shared/getSidebarItems"]);
  const isSubscribeStudent = computed(
    () => store.getters["students/studentInformation"].payment_status !== "Drop"
  );
  const getCurrentMenuItems = () => {
    let { role, completed_info } =
      store.getters["shared/getUserAuthentication"];

    if (role) {
      role = role.toUpperCase();
      switch (role) {
        case typesRoles[1]:
          currentSidebarItems.value = menuItems;
          break;
        case typesRoles[2]:
          currentSidebarItems.value = menuItems.filter(
            (i) => i.label !== "Analytics"
          );
          break;
        case typesRoles[3]:
          currentSidebarItems.value = teacherItemsData;
          break;
        case typesRoles[4]:
          // const showItems = completed_info;
          const showItems = completed_info;
          currentSidebarItems.value = showItems ? studentstemsData : [];
          break;
        default:
          currentSidebarItems.value = [];
          break;
      }
      store.commit("shared/uiUpdateSidebarItems", currentSidebarItems.value);
    }
  };

  /* **** VALIDATE ROLES ************** */
  const isAdmin = computed(() => {
    const user = store.getters["shared/getUserAuthentication"];
    return user.role === "admin" || user.role === "coordinator";
  });

  const isStudent = computed(
    () => store.getters["shared/getUserAuthentication"].role === "student"
  );

  const isTeacher = computed(
    () => store.getters["shared/getUserAuthentication"].role === "teacher"
  );

  watchEffect(() => {
    let user = store.getters["shared/getUserAuthentication"];
    if (user.completed_info) getCurrentMenuItems();
  });

  const sendNotificationClassesTeacher = async (total_classes) => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "total",
      value: total_classes,
      route: "teacher-calendar",
      next: total_classes + 100,
      // message: "You have achieved " + total_classes + " effective classes!",
      message:
        "Good job! You’ve taught " +
        total_classes +
        " lessons at Bright Lingua",
      title: "Congratulations",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const { token_firebase } = store.getters["teachers/teacherInfo"];
    if (token_firebase !== undefined) {
      let founded = {};
      const dbRef = refDb(database);
      if (!isSendingClassesTeachers.value) {
        isSendingClassesTeachers.value = true;
        await get(child(dbRef, `notifications/Teachers/` + token_firebase))
          .then(async (snapshot) => {
            let value_fb = snapshot.val();
            if (snapshot.exists()) {
              Object.entries(snapshot.val()).forEach(([key, value]) => {
                if (value.type === "total") {
                  founded = value;
                }
              });
              value_fb[notification.key] = notification;
              if (Object.entries(founded).length > 0) {
                if (total_classes === founded.next) {
                  await set(
                    refDb(
                      database,
                      "notifications/Teachers/" + token_firebase + "/"
                    ),
                    value_fb
                  ).then(async () => {});
                }
              } else {
                await set(
                  refDb(
                    database,
                    "notifications/Teachers/" + token_firebase + "/"
                  ),
                  value_fb
                ).then(async () => {});
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const sendNotificationRefer = async () => {
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "refer",
      route: "student-rewards",
      message: "Refer a friend and save 15%",
      title: "Remember",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
      nextDate: moment(new Date(store.getters['shared/currentTimeTimezone']))
        .add(15, "days")
        .format("YYYY-MM-DD HH:mm:ss"),
    };

    const { token_firebase } = store.getters["students/studentInformation"];

    if (token_firebase !== undefined) {
      let founded = {};
      const dbRef = refDb(database);
      if (!isSendingRefer.value) {
        isSendingRefer.value = true;
        await get(child(dbRef, `notifications/Students/` + token_firebase))
          .then(async (snapshot) => {
            if (snapshot.exists()) {
              let value_fb = snapshot.val();
              Object.entries(snapshot.val()).forEach(([key, value]) => {
                if (value.type === "refer") {
                  founded = value;
                }
              });
              value_fb[notification.key] = notification;
              if (Object.entries(founded).length > 0) {
                if (
                  moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:MM:ss") ===
                  founded.nextDate
                )
                  set(
                    refDb(
                      database,
                      "notifications/Students/" +
                        token_firebase +
                        "/" +
                        notification.key
                    ),
                    value_fb
                  );
              } else {
                await set(
                  refDb(
                    database,
                    "notifications/Students/" +
                      token_firebase +
                      "/" +
                      notification.key
                  ),
                  value_fb
                );
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

 



  const sendingPendingFeedbacksTeacher = async (classes) => {
    const notifications = {};
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      route: "teacher-students-feedback",
      message: "Don’t forget to fill out the teacher’s notes.",
      title: "Remember",
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };

    notifications[notification.key] = notification;

    const data = store.getters["teachers/teacherInfo"];

    if (data !== null && data.token_firebase !== undefined) {
      const dbRef = refDb(database);
      if (!isSendingFinishedClasses.value) {
        isSendingFinishedClasses.value = true;
        await get(child(dbRef, `notifications/Teachers/` + data.token_firebase))
          .then(async (snapshot) => {
            let value_fb = snapshot.val();
            if (snapshot.exists()) {
              value_fb = { ...value_fb, ...notifications };
              await set(
                refDb(
                  database,
                  "notifications/Teachers/" + data.token_firebase + "/"
                ),
                value_fb
              );
            } else {
              await set(
                refDb(
                  database,
                  "notifications/Teachers/" + data.token_firebase + "/"
                ),
                notifications
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };


  const sendingPendingFeedbacksTeacherAdmin = async (data) => {
    const teachers = {};

    data.forEach((teacher) => {
      const notification = {
        timezone: store.getters['shared/getTimezone'],
        key: uuidv4(),
        show: 0,
        leido: 0,
        route: "admin-members-teachers",
        message: `${teacher.name} ${teacher.last_name} has pending notes`,
        title: "Remember",
        date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
      };
      teachers[notification.key] = notification;
    });
    const dataAdmin = store.getters["admins/adminInfo"];
    if (dataAdmin !== null && dataAdmin.token_firebase !== undefined) {
      const dbRef = refDb(database);
      if (!isSendingFinishedClasses.value) {
        isSendingFinishedClasses.value = true;
        await get(
          child(dbRef, `notifications/Coordinators/` + dataAdmin.token_firebase)
        )
          .then(async (snapshot) => {
            let value_fb = snapshot.val();
            if (snapshot.exists()) {
              value_fb = { ...value_fb, ...teachers };
              await set(
                refDb(
                  database,
                  "notifications/Coordinators/" + dataAdmin.token_firebase + "/"
                ),
                value_fb
              );
            } else {
              await set(
                refDb(
                  database,
                  "notifications/Coordinators/" + dataAdmin.token_firebase + "/"
                ),
                teachers
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };


  const sendNotificationFailPayment = () => {
    //
    const student = store.getters["students/studentInformation"];
    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "payment",
      withId: student.user_id,
      route: "admin-member-student-billing",
      message: `The payment for ${student.name} ${student.last_name} with student’s code” ${student.user_id} has not been processed successfully`,
      title: "Payment",
      nextDate: moment(new Date(store.getters['shared/currentTimeTimezone'])).add(31, "d").format("YYYY-MM-DD HH:mm:ss"),
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const dbRef = refDb(database);
    get(child(dbRef, `Admins/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          Object.entries(snapshot.val()).forEach(([key, value]) => {
            get(child(dbRef, "notifications/Admins/" + key + "/"))
              .then((snapshot2) => {
                if (snapshot2.exists()) {
                  let found = {};
                  Object.entries(snapshot2.val()).forEach(([key2, value2]) => {
                    if (value2.type === "payment") {
                      found = value2;
                    }
                  });
                  if (Object.entries(found).length === 0) {
                    set(
                      refDb(
                        database,
                        "notifications/Admins/" + key + "/" + notification.key
                      ),
                      notification
                    );
                  } else {
                    if (
                      value.nextDate.substr(0, 10) ===
                      moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD")
                    ) {
                      set(
                        refDb(
                          database,
                          "notifications/Admins/" + key + "/" + notification.key
                        ),
                        notification
                      );
                    }
                  }
                } else {
                  set(
                    refDb(
                      database,
                      "notifications/Admins/" + key + "/" + notification.key
                    ),
                    notification
                  );
                }
              })
              .catch((error) => {
                // console.log("data not available");
              });
          });
          //
        } else {
          set(
            refDb(
              database,
              "notifications/Admins/" + key + "/" + notification.key
            ),
            notification
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendNotificationClassesDone = async (teacher) => {
    const coordinators = store.getters["teachers/coordinators"];

    const notification = {
      timezone: store.getters['shared/getTimezone'],
      key: uuidv4(),
      show: 0,
      leido: 0,
      type: "total",
      route: "admin-dashboard",
      message: `${teacher.teacher_name} has achieved ${teacher.total_lessons_done} effective lessons.`,
      title: "Classes done",
      next: teacher.total_lessons_done + 100,
      date: moment(new Date(store.getters['shared/currentTimeTimezone'])).format("YYYY-MM-DD HH:mm:ss"),
    };
    const dbRef = refDb(database);
    Object.entries(coordinators.data[0]).forEach(([key, value]) => {
      get(child(dbRef, `notifications/Coordinators/${value.token_firebase}/`))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            let value_fb = snapshot.val();
            let founded = {};
            Object.entries(snapshot.val()).forEach(([key, value]) => {
              if (value.type === "total") {
                founded = value;
              }
            });
            value_fb[notification.key] = notification;
            if (Object.entries(founded).length > 0) {
              if (teacher.total_lessons_done === founded.next)
                await set(
                  refDb(
                    database,
                    "notifications/Coordinators/" + value.token_firebase + "/"
                  ),
                  value_fb
                );
            } else {
              await set(
                refDb(
                  database,
                  "notifications/Coordinators/" + value.token_firebase + "/"
                ),
                value_fb
              );
            }
          } else {
            set(
              refDb(
                database,
                "notifications/Coordinators/" +
                  value.token_firebase +
                  "/" +
                  notification.key
              ),
              notification
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const goToBook = (isTeacher = false,teacher=null) => {
    store.commit("students/teacherToggleInfoModal", { isOpen: false });
    if (!isTeacher) {
      router.push({
        name: "student-teachers-scheduled-class",
        params: { isTeacher: false },
      });
    } else {
      router.push({
        name: "student-teachers-scheduled-class",
        params: { isTeacher: true , teacher:JSON.stringify(teacher)  },
      });
    }
  };

  const feedbackOrNoteConfirm = computed(() =>{
    return store.getters["shared/feedbackOrNoteConfirm"]
  })

  const toogleModalConfirmFeedback = () => {
    const { isOpenConfirm } = store.getters["shared/feedbackOrNoteConfirm"];
    console.log(isOpenConfirm);
    store.commit("shared/feedbackOrNoteConfirm", {
      isOpenConfirm : !isOpenConfirm,
    });
  }

  return {
    goToBook,
    toogleModalConfirmFeedback,
    feedbackOrNoteConfirm,
    sendNotificationClassesDone,
    sendNotificationClassesStudent,
    sendNotificationFailPayment,
    sendNotificationReward,
    sendNotificationFeedbackTeacher,
    sendNotificationTotalInscripcions,
    sendNotificationClassscheduled,
    sendNotificationFeedback,
    sendNotificationTeacherFavorite,
    sendNotificationCancelClass,
    userAuthenticated,
    toggleLogoutModal,
    logoutModalIsOpen,
    signOut,
    toggleNotification,
    notificationClass,
    notificationStatusInfo,
    closeNotificationStatusInfo,
    sendNotificationUpgradeLevel,
    toggleNotificationList,
    getNotificationList,
    getCurrentMenuItems,
    currentSidebarItems,
    sidebarItems,
    setShowNotificaciones,
    isAdmin,
    isStudent,
    isTeacher,
    sendingPendingFeedbacksTeacher,
    sendNotificationNewTeacher,
    isSubscribeStudent,
    sendNotificationRefer,
    sendNotificationClassesTeacher,
    sendingPendingFeedbacksTeacherAdmin,
    confirmModalOpen,
    confirmModalTitle,
    confirmModalQuestion,
    confirmModal,
    acceptRequest,
    configModalAccept
  };
};

export default useSharedUI;
