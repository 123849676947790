import { apiServiceNotToken } from "@/core/services/apiService";

const module = "user/";

const login = async (payload = {}) => {
  const ip = await getIpUser();

  const config = {
    endpoint: `${module}login`,
    data: {
      device: "desktop",
      ip,
      ...payload,
    },
    method: "POST",
  };
  return await apiServiceNotToken(config);
};

const getIpUser = async () => {
  try {
    const resp = await fetch("https://api.ipify.org/?format=json");
    const respJSON = await resp.json();
    return Object.values(respJSON) ? respJSON.ip : "1.1.1.1";
  } catch (error) {
    return "1.1.1.1";
  }
};

const validateToken = async (token) => {
  const config = {
    endpoint: `${module}validateTokenTime`,
    data: { token },
    method: "POST",
  };

  return await apiServiceNotToken(config);
};

const forgotPassword = async (email) => {
  const config = {
    endpoint: `${module}passwordReset`,
    data: { email },
    method: "POST",
  };

  return await apiServiceNotToken(config);
};

const resetPassword = async (payload) => {
  const config = {
    endpoint: `${module}passwordUpdate`,
    data: { ...payload },
    method: "POST",
  };

  return await apiServiceNotToken(config);
};

const loginByToken = async (token) => {
  const ip = await getIpUser();

  const config = {
    endpoint: `${module}loginByToken`,
    data: {
      device: "desktop",
      ip,
      token: token
    },
    method: "POST",
  };
  return await apiServiceNotToken(config);
}

export const authService = {
  login,
  resetPassword,
  validateToken,
  forgotPassword,
  loginByToken
};
