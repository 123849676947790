import { authService } from "../services/authServices";
import typesRoles from "@/shared/types/typesRoles";
const root = { root: true };
import router from "@/core/router/appRouter";

export const login = async ({ dispatch, commit }, payload) => {
  try {
    const response = await authService.login(payload);

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response },
      root
    );

    console.log(json)

    if (success) {
      let { role, token , is_new_student , has_random_password , completed_info , token_firebase , timezone } = json;
      role = typesRoles[role].toLowerCase();
      localStorage.setItem("bl-user", JSON.stringify({ role, token , is_new_student , has_random_password , completed_info , token_firebase , timezone }));
      commit(
        "shared/userAuthentication",
        { role, token, isLogged: true , is_new_student , has_random_password , completed_info , token_firebase , timezone },
        root
      );
    }
    return { success, json };
  } catch (error) {
    console.error(error);
  }
};

export const loginByToken = async ({ dispatch, commit }, token) => {
  try {
    const response = await authService.loginByToken(token);

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response },
      root
    );

    console.log(json)

    if (success) {
      let { role, token , is_new_student , has_random_password , completed_info , token_firebase , timezone } = json;
      role = typesRoles[role].toLowerCase();
      localStorage.setItem("bl-user", JSON.stringify({ role, token , is_new_student , has_random_password , completed_info , token_firebase , timezone }));
      commit(
        "shared/userAuthentication",
        { role, token, isLogged: true , is_new_student , has_random_password , completed_info , token_firebase , timezone },
        root
      );
    }
    return { success, json };
  } catch (error) {
    console.error(error);
  }
};

export const tokenValidate = async ({ dispatch, commit }, token) => {
  try {
    const response = await authService.validateToken(token);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response, isShowError: false },
      root
    );
    if (!success) {
      const { errors } = json;
      commit(
        "shared/uiNotificationStatus",
        {
          status: "error",
          message: errors[0],
          isOpen: true,
        },
        root
      );
    }
    return { success, json };
  } catch (error) {
    console.error(error);
  }
};

export const forgotPassword = async ({ dispatch, commit }, email) => {
  try {
    const response = await authService.forgotPassword(email);

    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response, isShowError: false },
      root
    );
    const notification = { isOpen: true };

    if (!success) {
      const { errors } = json;
      notification.status = "error";
      notification.message = errors[0];
    } else {
      const { message } = json;
      notification.status = "info";
      notification.message = message;
    }

    commit("shared/uiNotificationStatus", notification, root);

    return { success, json };
  } catch (error) {
    //console.log(error);
  }
};

export const resetPassword = async ({ dispatch, commit }, payload) => {
  try {
    const response = await authService.resetPassword(payload);
    const { success, json } = await dispatch(
      "shared/validateHttpStatus",
      { response, isShowError: false },
      root
    );

    const notification = { isOpen: true };

    if (!success) {
      const { errors } = json;
      notification.status = "error";
      notification.message = errors[0];
    } else {
      const { message } = json;
      notification.status = "success";
      notification.message = message;
      setTimeout(() => {
        router.push('/')
      },3000)
    }
    commit("shared/uiNotificationStatus", notification, root);

    return { success, json };
  } catch (error) {
    //console.log(error);
  }
};
