export default {
  name: "admin",
  component: () =>
    import(
      /* webpackChunkName: "Private Layout" */ "@/shared/layouts/PrivateLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "admin-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Dashboard View" */ "@/modules/admin/views/Dashboard/DashboardView.vue"
        ),
    },
    {
      path: "admin-library",
      name: "admin-library",
      component: () =>
        import(
          /* webpackChunkName: "Library View" */ "@/modules/admin/views/Library/LibraryView.vue"
        ),
    },
    {
      path: "admin-library-content",
      name: "admin-library-content",
      component: () =>
        import(
          /* webpackChunkName: "My Library Level Content View" */ "@/shared/views/Library/LibraryLevelContentView.vue"
        ),
    },
    {
      path: "admin-members-teachers",
      name: "admin-members-teachers",
      component: () =>
        import(
          /* webpackChunkName: "Members Teachers" */ "@/modules/admin/views/Members/Teachers/MemberTeacherView.vue"
        ),
    },

    {
      path: "admin-members-teachers/edit-profile-teacher/:id",
      name: "admin-member-teacher-edit-profile",
      component: () =>
        import(
          /* webpackChunkName: "Member Student Edit Profile View" */ "@/modules/admin/views/Members/Teachers/MemberTeacherEditProfile.vue"
        ),
    },
    {
      path: "admin-members-teachers/scheduled-job/:id",
      name: "admin-member-teacher-scheduled-job",
      component: () =>
        import(
          /* webpackChunkName: "Analytics Marketing View" */ "@/modules/admin/views/Members/Teachers/MemberTeacherScheduledJob.vue"
        ),
    },
    {
      path: "admin-members-students",
      name: "admin-members-students",
      component: () =>
        import(
          /* webpackChunkName: "Members Students View" */ "@/modules/admin/views/Members/Students/MemberStudentView.vue"
        ),
    },
    {
      path: "admin-members-students/profile-student/:id",
      name: "admin-member-student-profile",
      component: () =>
        import(
          /* webpackChunkName: "Member Student Profile View" */ "@/modules/admin/views/Members/Students/MemberStudentProfileView.vue"
        ),
    },
    {
      path: "admin-members-students/edit-profile-student/:id",
      name: "admin-member-student-edit-profile",
      component: () =>
        import(
          /* webpackChunkName: "Member Student Edit Profile View" */ "@/modules/admin/views/Members/Students/MemberStudentEditProfile.vue"
        ),
    },
    {
      path: "admin-members-students/billing-student/:id",
      name: "admin-member-student-billing",
      component: () =>
        import(
          /* webpackChunkName: "Member Student Billing View" */ "@/modules/admin/views/Members/Students/MemberStudentBillingView.vue"
        ),
    },
    {
      path: "admin-members-admins",
      name: "admin-members-admins",
      component: () =>
        import(
          /* webpackChunkName: "Members Admins View" */ "@/modules/admin/views/Members/Admins/MemberAdminView.vue"
        ),
    },
    {
      path: "admin-calendar",
      name: "admin-calendar",
      component: () =>
        import(
          /* webpackChunkName: "Calendar View" */ "@/modules/admin/views/Calendar/CalendarView.vue"
        ),
    },
    // {
    //   path: "admin-analytics",
    //   name: "admin-analytics",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "Analytics View" */ "@/modules/admin/views/Analytics/AnalyticsView.vue"
    //     ),
    // },
    {
      path: "admin-analytics-financial",
      name: "admin-analytics-financial",
      component: () =>
        import(
          /* webpackChunkName: "Analytics Financial View" */ "@/modules/admin/views/Analytics/Financial/FinancialView.vue"
        ),
    },
    {
      path: "admin-analytics-marketing",
      name: "admin-analytics-marketing",
      component: () =>
        import(
          /* webpackChunkName: "Analytics Marketing View" */ "@/modules/admin/views/Analytics/Marketing/MarketingView.vue"
        ),
    },
    {
      path: ":pathMatch(.*)*",
      redirect: {
        name: "admin-dashboard",
      },
    },
  ],
};
