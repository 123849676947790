import moment from 'moment'

const dateStartWeek = new Date()
dateStartWeek.setDate(dateStartWeek.getDate())
dateStartWeek.setHours(0,0,0)
const dateEndWeek = new Date()
dateEndWeek.setDate(dateEndWeek.getDate() + 6)
dateEndWeek.setHours(23,59,59)

export default () => ({
  modals: {
    dashboard: {},
    members: {
      sendEmails: {
        isOpen: false,
        templates: [],
        emails: [],
      },
      resetEmails: {
        isOpen: false,
        emails: [],
      },
      students: {
        lead: {
          isOpen: false,
          save: false,
        },
        upgradeLevel: {
          isOpen: false,
          data: {},
        },
      },
      teachers: {
        addOrUpdate: {
          isOpen: false,
          status: "",
          loadingInfo: true,
        },
        changeRole: {
          isOpen: false,
        },
        blockTeacher:{
          isOpen: false,
          idTeacher:null
        }
      },
      admins: {
        addOrUpdate: {
          isOpen: false,
          status: "",
          loadingInfo: true,
          editToAdmin: {},
        },
      },
      feedback: {
        isOpen: false,
        data: {},
        isStudent: false,
      },
    },
    mylibrary: {
      contentAdd: {
        isOpen: false,
        data: null,
        isEdit: false,
        isUpdate: false,
        levelIdUpdate: null,
      },
    },
    myCalendar: {
      data: {},
      isOpen: false,
    },
    analytics: {
      financial: {
        exportCharts: {
          isOpen: false,
        },
      },
      marketing: {
        exportCharts: {
          isOpen: false,
        },
        approveRewards: {
          isOpen: false,
          data: [],
          dataSelect: [],
        },
      },
    },
  },
  adminInfo: {
    isLoading: false,
    total_users: 0,
  },
  dashboard: {
    students: {
      data: {},
      isLoading: true,
    },
    teachers: {
      data: {},
      isLoading: true,
    },
  },
  members: {
    students: {
      profile: {
        isLoading: false,
        data: {},
      },
      billing: {
        isLoading: false,
        table: {
          header: [
            {
              field: "date",
              name: "Date",
            },
            {
              field: "payment_status",
              name: "Payment status",
            },
            {
              field: "amount",
              name: "Amount",
            },
          ],
          data: [
            {
              date: "2020-05-01",
              payment_status: "Processing",
              amount: "200.00",
            },
            {
              date: "2020-05-01",
              payment_status: "Succesfull",
              amount: "10.00",
            },
            {
              date: "2020-05-01",
              payment_status: "Payment declined",
              amount: "150.00",
            },
          ],
          isLoading: true,
        },
      },

      tables: {
        basicInfo: {
          header: [
            { field: "status", name: "Status", sortable: true, filter: true },
            { field: "id", name: "Student ID", sortable: true , filter : false },
            { field: "name", name: "Name(s)", sortable: false },
            { field: "gender", name: "Gender", sortable: true },
            { field: "tel", name: "Phone", sortable: false },
            { field: "city", name: "City", sortable: true },
            { field: "payment-method", name: "Payment method", sortable: true },
            { field: "enrollment", name: "Enrollment", sortable: true },

            { field: "email", name: "Email", sortable: false },
            { field: "birth", name: "Birthday", sortable: false },
            { field: "age", name: "Age", sortable: true },
            { field: "level", name: "Level", sortable: true },
            {
              field: "enrollment_date",
              name: "Enrollment date",
              sortable: true,
            },
            {
              field: "first_payment_date",
              name: "First payment date",
              sortable: true,
            },

            {
              field: "cant_effective_classes",
              name: "Effective number of classes held",
              sortable: true,
            },
            {
              field: "cant_classes_cancelled",
              name: "Number of classes canceled",
              sortable: true,
            },
            {
              field: "apply_discount_code",
              name: "Applied discount code",
              sortable: true,
            },
            {
              field: "rewards_video",
              name: "Rewards video",
              sortable: true,
            },
          ],
          data: [],
          isLoading: true,
          currentOptionReport: "default",
          dateRanges: [],
          selectedColumns: [
            { field: "status", header: "Status", sortable: true },
            { field: "id", header: "Student ID", sortable: false },
            { field: "name", header: "Name", sortable: false },
            { field: "gender", header: "Gender", sortable: true },
            { field: "email", header: "Email", sortable: false },
            { field: "tel", header: "Phone", sortable: true },
            {
              field: "payment-method",
              header: "Payment method",
              sortable: true,
            },
            { field: "enrollment", header: "Enrollmnent", sortable: true },
            { field: "birth", header: "Birthday", sortable: false },
            { field: "age", header: "Age", sortable: true },
            { field: "level", header: "Level", sortable: true },
            {
              field: "enrollment_date",
              header: "Enrollment date",
              sortable: true,
            },
            {
              field: "first_payment_date",
              header: "First payment date",
              sortable: true,
            },
            {
              field: "cant_effective_classes",
              header: "Effective number of classes held",
              sortable: true,
            },
            {
              field: "cant_classes_cancelled",
              header: "Number of classes canceled",
              sortable: true,
            },
            {
              field: "apply_discount_code",
              header: "Applied discount code",
              sortable: true,
            },
            { field: "rewards_video", header: "Rewards video", sortable: true },
          ],
        },
      },
    },
    teachers: {
      currentTable: "basic-info",
      basicInfo: {
        header: [
          { field: "status", name: "Status", sortable: true, filter: true },
          { field: "id", name: "Teacher ID", sortable: false },
          { field: "name", name: "Name(s)", sortable: false },
          { field: "email", name: "Email", sortable: false },
          { field: "cellphone", name: "Phone", sortable: false },
          { field: "age", name: "Age", sortable: true, filter: true },
          { field: "birth", name: "Birthday", sortable: false },
          { field: "gender", name: "Gender", sortable: true, filter: true },
          { field: "time_zone", name: "Time zone", sortable: true },
          {
            field: "cant_classes_completed",
            name: "Number of classes completed",
            sortable: false,
          },
          {
            field: "number_times_marked",
            name: "Number of times marked as favorite",
            sortable: false,
          },
        ],
        data: [],
        isLoading: true,
        currentOptionReport: "default",
        dateRanges: [],
      },
      account: {
        header: [
          { field: "name", name: "Name(s)", sortable: true },
          { field: "email", name: "Email", sortable: true },
          { field: "role", name: "Role", sortable: true },
        ],
        data: [],
        isLoading: true,
      },
      teacher: {
        data: {},
      },
      scheduled: {
        isOpen: false,
        clear:{
          start: new Date(),
          end:new Date()
        },
        deleteEvents:[],
        events: [],
        isOpenCreate:false,
        isOpenDeleteEvent:false,
        isOpenLastChanges:false,
        idDeleteEvent:null,
        hasClass:false,
        isOpenListTeachers:false,
        availableTeachers:[],
        isLoadingSchedule:false,
        timezoneTeacher:"",
        staticTimezones:[
          { label: "General: US/Eastern", value: "US/Eastern" },
        ],
        weekDates:{
          start: moment().startOf("week").add(1, "days"),
          end: moment().endOf("week").add(1, "days"),
        },
        isOpenAcceptChange:false,
        dataClass:null,
        isLoadingSave:false,
        newEvent: {
          start:new Date(),
          end:new Date(),
        },
        isLoading: true,
      },
    },
    admins: {
      currentTable: "basic-info",
      basicInfo: {
        header: [
          { field: "status", name: "Status", sortable: true, filter: true },
          { field: "name", name: "Name(s)", sortable: false },
          { field: "email", name: "Email", sortable: false },
          { field: "cellphone", name: "Phone", sortable: false },
          { field: "age", name: "Age", sortable: true, filter: true },
          { field: "birth", name: "Birthday", sortable: false },
          { field: "gender", name: "Gender", sortable: true, filter: true },
          { field: "timezone", name: "Time zone", sortable: true },
        ],
        data: [],
        dateRanges: [],
        isLoading: true,
      },
      account: {
        header: [
          { field: "name", name: "Name(s)", sortable: true },
          { field: "email", name: "Email", sortable: true },
          { field: "role", name: "Role", sortable: true, filter: true },
        ],
        data: [],
        isLoading: true,
      },
    },
  },
  analytics: {
    financial: {
      lastPays: {
        isLoading: true,
        data: [],
        header: [
          { field: "name", name: "Name(s)" },
          { field: "status", name: "Payment status" },
          { field: "amount", name: "Amount" },
          { field: "date", name: "Date" },
        ],
      },
      totals: {
        isLoading: true,
        data: {},
      },
      exportCharts: {
        Weekly: {
          labels: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          datasets: [
            {
              type: "bar",
              label: "Weekly",
              data: [0, 0, 0, 0, 0, 0],
              borderRadius: 20,
              backgroundColor: [
                "#74DAD9",
                "#FAB086",
                "#74DAD9",
                "#74DAD9",
                "#74DAD9",
                "#74DAD9",
                "#74DAD9",
              ],
            },
          ],
        },
        Monthly: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              type: "bar",
              label: "Monthly",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              borderRadius: 20,
              backgroundColor: [
                "#74DAD9",
                "#FAB086",
                "#74DAD9",
                "#74DAD9",
                "#74DAD9",
                "#74DAD9",
                "#74DAD9",
              ],
            },
          ],
        },
        total: {
          Weekly: {
            order: "0.00",
            total: "0.00",
          },
          Monthly: {
            order: "0.00",
            total: "0.00",
          },
        },
      },
    },
    marketing: {
      lastRegisters: {
        isLoading: true,
        data: [],
        header: [
          { field: "name", name: "Name(s)", sortable: false },
          { field: "type_register", name: "Registion type", sortable: true },
          { field: "date", name: "Date", sortable: false },
        ],
      },
      graphic: {
        isLoading: true,
        data: {
          total_registers_week: {},
          total_registers_month: {},
        },
      },
      rewards: {
        currentTable: "rewards-approval",
        approval: {
          isLoading: true,
          data: [],
          header: [
            { field: "name", name: "Name(s)", sortable: false },
            { field: "email", name: "Email", sortable: false },
            {
              field: "user_payment_status",
              name: "Payment status",
              sortable: true,
            },
            { field: "claimend", name: "Claimed", sortable: false },
            { field: "renewal", name: "Renewal", sortable: false },
            { field: "reward", name: "Reward", sortable: true },
            { field: "discount", name: "Discount", sortable: false },
          ],
        },
        approved: {
          isLoading: true,
          data: [],
          header: [
            { field: "name", name: "Name(s)", sortable: false },
            { field: "email", name: "Email", sortable: false },
            {
              field: "user_payment_status",
              name: "Actual Student Status",
              sortable: true,
            },
            {
              field: "reward",
              name: "Reward claimed",
              sortable: true,
            },
            {
              field: "student_referred",
              name: "Referred student",
              sortable: false,
            },
            { field: "student_referred_email", name: "Email", sortable: false },
            { field: "claimend", name: "Claimed", sortable: false },
            { field: "discount", name: "Discount", sortable: true },
          ],
        },
      },
    },
  },
  progressStudents: {
    isLoading: true,
    data: {},
  },
  myCalendar: {
    isLoading: true,
    data: [],
  },
  previsualize: {
    data: {},
    isView: false,
    isEdit: false,
  },
  pendingTeachers: {
    isLoading: true,
    data: [],
  },
  teachersClassesDone: {
    isLoading: true,
    data: [],
  },
  emailTemplates: {
    data: [],
    isLoading: true,
  },
});
